import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const Instructions = () => {
  return (
    <>
      <Typography className="mt-4">
        <Title>Get started</Title>
        <Paragraph>
          If you have the information you need, it will take around 15 minutes to complete the application.
        </Paragraph>
        <Title level={4}>Eligibility requirements</Title>
        <Paragraph>
          If you are a child care provider located on a military base, you are <Text strong>NOT</Text> eligible for
          Child Care Aware of America’s Fee Assistance Programs.{' '}
        </Paragraph>
        <Title level={4}>Documents to gather</Title>
        <Paragraph>Gather the following documents for your application to Child Care Aware of America.</Paragraph>
        <Paragraph>
          <ul>
            <li>
              <Paragraph>
                <Text strong>IRS Form W-9</Text>
                <span className="text-red-600">*</span>: Use Form W-9 to enter your Legal Name, Taxpayer Identification
                Number (TIN), tax classification information, address, signature and date.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>State license</Text>
                <span className="text-red-600">*</span>: You must have a valid license issued by your state to
                participate in the Fee Assistance Programs. The license should cover all ages served by the facility.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>Current child care rates</Text>
                <span className="text-red-600">*</span>: All fees and discounts that apply to the families receiving
                care at your facility.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>Employer Identification Number (EIN) certificate</Text>: The Internal Revenue Service sends
                a tax ID verification letter to confirm your new EIN.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>A direct deposit form OR bank letter OR voided check</Text>: You will need a voided check
                printed by the bank to enroll your facility in direct deposit. The business or legal name must match the
                name in your application.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>National accreditation</Text>: Documentation of any national accreditation.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                <Text strong>Quality Rating and Improvement System (QRIS)</Text>: Documentation of any quality ratings
                by your state QRIS.
              </Paragraph>
            </li>
          </ul>
        </Paragraph>
      </Typography>
    </>
  );
};
export default Instructions;
