import React, { useEffect } from 'react';
import { Input, Button, message, Divider } from 'antd';
import { useAsyncFn, useAsync } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import Form from 'components/Form';
import { selectors, actions } from 'features/auth';
import providerService from 'services/providers.service';

export default function ProviderChangeBusinessName({ setVisible }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const history = useHistory();
  const organization = useSelector(selectors.selectOrganization);
  const user = useSelector(selectors.selectUser);
  const [{ loading: savingOrganization }, saveOrganization] = useAsyncFn(
    async (values) => {
      try {
        const org = await providerService.updateOrganization(organization?.id, {
          ...organization,
          ...values,
        });
        message.success(organization?.id ? 'Done' : 'Organization created successfully.');
        form.setFieldsValue({ businessName: values?.businessName });

        dispatch(actions.setOrganization(org));

        setVisible(false);
      } catch (error) {
        message.error(organization?.id ? 'Unable to update.' : 'Unable to create organization');
        newrelic.noticeError(error);
      }
    },
    [organization],
  );

  useAsync(async () => {
    const organization = await providerService.getOrganization();
    dispatch(actions.setOrganization(organization));
  }, []);

  useEffect(() => {
    if (!organization?.businessName) saveOrganization({ businessName: user?.firstName + ' ' + user?.lastName });
  }, [organization?.businessName, saveOrganization, user?.firstName, user?.lastName]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        saveOrganization(values);
      }}
      initialValues={{ businessName: organization?.businessName }}
    >
      <div className="mt-8">
        <div className="flex-1">
          <Form.Item
            name="businessName"
            label="Business Name"
            rules={[
              { required: true, message: 'This is required' },
              { min: 3, message: 'Minimum length is 3 characters' },
              { max: 60, message: 'Maximum length is 60 characters' },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>

      <Divider />

      <div className="flex justify-end space-x-4">
        <Button
          onClick={() => {
            setVisible(false);
          }}
          loading={savingOrganization}
          data-testid="cancel-btn"
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={savingOrganization}
          data-testid="save-btn"
        >
          Save
        </Button>
      </div>
    </Form>
  );
}
