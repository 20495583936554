import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Form from 'components/Form';
import QRIS from 'components/QRIS';
import FormItem from 'components/FormItem';
import Licenses from 'components/Licenses';
import Insurances from 'components/Insurances';
import Accreditation from 'components/Accreditation';
import providerService from 'services/providers.service';
import { actions as documentActions, selectors as documentSelectors } from 'features/documents';
import { ADD_FACILITY_STEPS, FACILITY_DETAILS_TABS } from 'constants/index';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import { sleep, parseDocumentsForServer, parseDocuments } from 'utils';
import Card from 'components/Card/Card';

export default function CenterDetails({ setTab, onCancel, setOpenTabs, center, actions, isFormSubmitting, setStep }) {
  const [form] = Form.useForm();
  const isSubmitDisabled = useProviderEditDisabled();
  const dispatch = useDispatch();
  const { documents = {}, allDocuments } = useSelector(documentSelectors.selectDocumentsState);

  // Keeps track of whether `next` is pressed or `save`
  const shouldGoToNextStep = useRef(false);

  useEffect(() => {
    const initialValues = { ...center, documents };
    if (center.hoursOfOperation) {
      initialValues.hoursOfOperation = center.hoursOfOperation.map((item) => {
        const data = { ...item };
        if (item.openingTime) {
          data.openingTime = dayjs(item.openingTime, 'HH:mm');
        }
        if (item.closingTime) {
          data.closingTime = dayjs(item.closingTime, 'HH:mm');
        }
        return data;
      });
    }

    if (initialValues?.licenses) {
      if (initialValues?.licenses?.length) {
        initialValues.isStateLicensed = true;
      } else {
        initialValues.isStateLicensed = false;
      }
    }

    form.setFieldsValue(initialValues);
  }, [form, center, documents]);

  // const showInspectionReport =
  //   ['CONNECTICUT', 'RHODE ISLAND', 'HAWAII'].indexOf(center?.address?.state?.toUpperCase()) > -1;
  const showInspectionReport = true;
  const showInsuranceSection = useMemo(
    () => center?.application?.programCategories?.find?.((v) => v.title === 'Non-Traditional Care'),
    [center?.application?.programCategories],
  );

  return (
    <div id="provider-center-details-form">
      <Form
        name="center-details"
        layout="vertical"
        preserve
        onKeyDown={(e) => (e.code === 'Enter' ? e.preventDefault() : '')}
        form={form}
        onFinish={async (values) => {
          dispatch(actions.setIsFormSubmitting(true));
          try {
            const data = await providerService.updateCenter(
              { ...values, licenses: values.isStateLicensed === false ? [] : values.licenses },
              center.id,
            );
            const { allDocuments: newAllDocuments } = await parseDocumentsForServer(values, {
              documents,
              allDocuments,
            });
            dispatch(actions.setCenterInfo(data));
            dispatch(documentActions.setAllDocuments(newAllDocuments));
            dispatch(documentActions.setDocuments(parseDocuments(newAllDocuments)));
            setOpenTabs(FACILITY_DETAILS_TABS.SCHEDULE);

            if (shouldGoToNextStep.current) {
              await sleep(100);
              setTab(FACILITY_DETAILS_TABS.SCHEDULE);
            }

            message.success('Saved');
          } catch (error) {
            newrelic.noticeError(error);
            message.error('Unable to save center details.');
          } finally {
            dispatch(actions.setIsFormSubmitting(false));
          }
        }}
        scrollToFirstError
      >
        {() => {
          return (
            <Spin spinning={isFormSubmitting}>
              <div className="mb-4">
                <Form.Item name="licenses" noStyle>
                  <Licenses center={center} />
                </Form.Item>
              </div>

              {showInspectionReport && (
                <Card collapsible title="Inspection Report" className="mb-4">
                  <p className="mb-6">
                    Please provide a link to your most recent state child care licensing inspection report.{' '}
                  </p>

                  <FormItem label="Providers inspection report link" name={['inspectionReportLink']}>
                    <Input />
                  </FormItem>
                </Card>
              )}

              <div className="mb-4">
                <Form.Item name="accreditations" noStyle>
                  <Accreditation center={center} form={form} />
                </Form.Item>
              </div>

              <div className="mb-4">
                <Form.Item name="qrisList" noStyle>
                  <QRIS center={center} state={center?.address?.state} />
                </Form.Item>
              </div>

              {showInsuranceSection && <Insurances center={center} />}

              <Card>
                <div className="flex space-x-2">
                  <Button onClick={() => setStep(ADD_FACILITY_STEPS.DETAILS, FACILITY_DETAILS_TABS.BUSINESS_INFO)}>
                    Back
                  </Button>

                  <div className="flex-grow"></div>

                  <Button
                    loading={isFormSubmitting}
                    disabled={isFormSubmitting || isSubmitDisabled}
                    onClick={() => {
                      shouldGoToNextStep.current = false;

                      setTimeout(() => {
                        form.submit();
                      });
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    type="primary"
                    loading={isFormSubmitting}
                    disabled={isFormSubmitting || isSubmitDisabled}
                    data-testid="center-details-submit"
                    onClick={() => {
                      shouldGoToNextStep.current = true;

                      setTimeout(() => {
                        form.submit();
                      });
                    }}
                  >
                    Next
                  </Button>
                </div>
              </Card>
            </Spin>
          );
        }}
      </Form>
    </div>
  );
}

CenterDetails.propTypes = {
  actions: PropTypes.shape({
    setCenterInfo: PropTypes.func,
  }),
  center: PropTypes.shape({
    accreditations: PropTypes.array,
    hoursOfOperation: PropTypes.array,
    licenses: PropTypes.array,
    qrisList: PropTypes.array,
    address: PropTypes.shape({
      state: PropTypes.string,
    }),
    id: PropTypes.number,
  }),
  dispatch: PropTypes.func,
  onCancel: PropTypes.func,
  setOpenTabs: PropTypes.func,
  setTab: PropTypes.func,
};
