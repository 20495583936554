import { useCallback, useState } from 'react';
import { Avatar, message, Button, Modal, Input, Dropdown } from 'antd';
import { Auth } from 'aws-amplify';
import { useAsyncCallback } from 'react-async-hook';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaUserCircle } from 'react-icons/fa';
import startCase from 'lodash/startCase';
import { selectRoles, actions, selectUser, selectActiveRole, selectors } from 'features/auth';
import { ROLES } from 'constants/index';
import Form from 'components/Form';
import GccModal from 'components/Modal';
import ResetPasswordFields from 'components/Authentication/ResetPassword/ResetPasswordFields';
import ChangeUserName from 'components/Authentication/ChangeUserName';
import { Logout, Password, Portfolio, User } from '@carbon/icons-react';
import ProviderChangeBusinessName from 'components/ProviderChangeBusinessName/ProviderChangeBusinessName';

export default function SwitchRolesMenu({ theme }) {
  const [visible, setVisible] = useState(false);
  const [visibleChangeUserNameModal, setVisibleChangeUserNameModal] = useState(false);
  const [visibleProviderChangeBusinessName, setVisibleProviderChangeBusinessName] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const initials = [user.firstName, user.lastName]
    .filter((v) => v)
    .map((v) => v.charAt(0))
    .join('');
  const organization = useSelector(selectors.selectOrganization);

  // TODO: need to add logic to get current active role
  const activeRole = useSelector(selectActiveRole);
  const logout = useCallback(async () => {
    dispatch(actions.logout.pending());
  }, [dispatch]);

  const changePassword = useAsyncCallback(async (values) => {
    return (
      Auth.currentAuthenticatedUser()
        // return Auth.signIn(user.email, values.currentPassword)
        .then((user) => {
          return Auth.changePassword(user, values.currentPassword, values.password);
        })
        .then((data) => {
          message.success('Password changed successfully');
          setVisible(false);
        })
        .catch((err) => {
          message.error(err.message || 'Unable to change password.');
        })
    );
  });
  const goToDashboard = useCallback(
    async (role) => {
      if (role === ROLES.PROVIDER) {
        history.push('/provider');
      } else if (role === ROLES.SYSTEM_ADMIN) {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    },
    [history],
  );
  const setActiveRole = useCallback(
    (role) => {
      Modal.confirm({
        title: <h3 className="text-primary text-base font-semibold uppercase">Switch Role</h3>,
        content: (
          <span className="text-black text-15 text-medium">
            Are you sure you want to change your role to <b>{startCase(role)}</b> ?
          </span>
        ),
        icon: null,
        okText: 'Change Role',
        width: 550,
        onOk() {
          // Just to show some loaidng before closing Modal
          return new Promise((res) => {
            setTimeout(() => {
              history.push('/'); // this step is required to avoid unexpected api calls with unexpected active role.
              dispatch(actions.setActiveRole(role));
              goToDashboard(role);
              message.success('Role has been updated.', 3);
              res();
            }, 1000);
          });
        },
        bodyStyle: {
          padding: 20,
        },
      });
    },
    [dispatch, goToDashboard, history],
  );

  const formActions = (
    <div className="actions">
      <Button
        disabled={changePassword.loading}
        onClick={() => {
          setVisible(false);
        }}
      >
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" loading={changePassword.loading} disabled={changePassword.loading}>
        Submit
      </Button>
    </div>
  );

  return (
    <>
      <Dropdown
        menu={{
          selectedKeys: [activeRole],
          items: [
            {
              key: activeRole,
              label: startCase(activeRole),
              onClick: () => {
                goToDashboard(activeRole);
              },
            },
            ...(roles.length > 1
              ? [
                  {
                    key: 'divider_1',
                    type: 'divider',
                  },
                  ...roles
                    .filter((role) => role !== activeRole)
                    .sort()
                    .map((role) => ({
                      key: role,
                      label: startCase(role),
                      onClick: () => {
                        setActiveRole(role);
                      },
                    })),
                ]
              : []),
            {
              key: 'divider_2',
              type: 'divider',
            },
            {
              key: 'change_username',
              label: 'Change username',
              icon: <User />,
              onClick: () => {
                setVisibleChangeUserNameModal(true);
              },
            },
            {
              key: 'change_password',
              label: 'Change password',
              icon: <Password />,
              onClick: () => {
                setVisible(true);
              },
            },
            ...(organization && organization?.businessName
              ? [
                  {
                    key: 'change_business_name',
                    label: 'Change business name',
                    icon: <Portfolio />,
                    onClick: () => {
                      setVisibleProviderChangeBusinessName(true);
                    },
                  },
                ]
              : []),
            {
              key: 'divider_3',
              type: 'divider',
            },
            {
              key: 'logout',
              danger: true,
              label: 'Logout',
              icon: <Logout />,
              onClick: logout,
            },
          ],
        }}
        trigger={['click']}
      >
        <a onClick={(e) => e.preventDefault()} data-testid="header-menu-trigger">
          {initials ? (
            <Avatar
              className={classNames(
                {
                  'text-black bg-white': theme !== 'primary',
                  'text-primary bg-primary-faded': theme === 'primary',
                },
                'select-none',
                'mt-[-2px]',
              )}
              size={30}
            >
              <span className="block mt-[-1px]">{initials.toUpperCase?.()}</span>
            </Avatar>
          ) : (
            <FaUserCircle
              size={36}
              className={classNames({
                'text-primary': theme === 'primary',
                'text-white': theme !== 'primary',
              })}
            />
          )}
        </a>
      </Dropdown>

      <GccModal
        visible={visible}
        setVisible={setVisible}
        onCancel={() => {
          setVisible(false);
        }}
        title="Change password"
      >
        <Form data-testid="change-password-form" layout="vertical" onFinish={changePassword.execute}>
          {(values, form) => (
            <>
              <Form.Item
                rules={[{ required: true, message: 'Enter your current password.' }]}
                name="currentPassword"
                label="Current Password"
              >
                <Input type="password" placeholder="Current Password" />
              </Form.Item>

              <ResetPasswordFields {...values} form={form} actions={formActions} />
            </>
          )}
        </Form>
      </GccModal>

      <GccModal
        visible={visibleChangeUserNameModal}
        setVisible={setVisibleChangeUserNameModal}
        onCancel={() => setVisibleChangeUserNameModal(false)}
        title="Change username"
      >
        <ChangeUserName setVisible={setVisibleChangeUserNameModal} />
      </GccModal>

      <GccModal
        visible={visibleProviderChangeBusinessName}
        setVisible={setVisibleProviderChangeBusinessName}
        onCancel={() => setVisibleProviderChangeBusinessName(false)}
        title="Change business name"
      >
        <ProviderChangeBusinessName setVisible={setVisibleProviderChangeBusinessName} />
      </GccModal>
    </>
  );
}
