import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import Header from 'components/Header';
import useBreakpoints from 'hooks/useBreakpoints';
import CompactSearch from 'components/CompactSearch';
import NavigationTabs from 'components/NavigationTabs';
import HomePageElements from 'components/HomePageElements';
import styles from './Home.module.less';
import { actions } from 'features/search';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';

const { Content, Footer } = Layout;

export default function Home() {
  const dispatch = useDispatch();
  const { md, screen } = useBreakpoints();
  const height = use100vh();
  const pageStyles = {};
  if (screen === 'xs') {
    pageStyles.height = height;
  }
  useEffect(() => {
    dispatch(actions.resetSearch());
  }, [dispatch]);

  return (
    <Layout className={styles.home} style={{ minHeight: height }}>
      <HomePageElements screen={screen} />
      <Header
        position="relative"
        className="page-header bg-white shadow-xl"
        centerContent={
          md ? (
            <div className="flex flex-col header-center-content">
              <NavigationTabs theme="primary" className="center w-full mb-5" />

              {/* <SearchBar
                onSubmit={(params) => {
                  const queryString = paramsToQueryString(params);
                  history.push(`/search?${queryString}`);
                }}
              /> */}
            </div>
          ) : null
        }
        theme="primary"
      />
      <Content className="container px-5 md:px-12 xl:px-16">
        {/* <NavigationTabs
          selected="childcare"
          className="hidden sm:block center w-full md:max-w-xl lg:max-w-2xl xl:-mt-16 mb-5 mx-auto primary"
        />*/}
        {(screen === 'xs' || screen === 'sm') && (
          <CompactSearch placeholder="Location, zipcode, or address" className="-mt-8" />
        )}
        <div className="title-container">
          <h1 className="title">
            Start your
            <br />
            reimagined
            <br />
            child care journey
          </h1>
        </div>
        <div className="stats-container">
          {/* <motion.div
            animate={animation ? 'active' : 'initial'}
            variants={variants}
            transition={{ duration: 0.5 }}
            className="stats"
          >
            <Col fill="auto" className="stats-box">
              <span className="count">22,000</span>
              <span className="name">Providers</span>
            </Col>
            <Divider type="vertical" />
            <Col fill="auto" className="stats-box">
              <span className="count">15,000</span>
              <span className="name">Vacancies</span>
            </Col>
            <Divider type="vertical" />
            <Col fill="auto" className="stats-box">
              <span className="count">50</span>
              <span className="name">States</span>
            </Col>
          </motion.div> */}

          <div className="absolute bottom-0 left-0">
            <div className="relative">
              <div className="absolute top-0 left-0 py-6 mt-8 text-white min-w-max">
                <p className="text-lg font-medium">Register to use our Fee Assistance Programs</p>
                <div className="flex space-x-3 my-5">
                  <Link to="/family/signup">
                    <Button
                      type="primary"
                      className="!bg-blue-800 hover:!bg-blue-900 transition-all w-52 h-12 !rounded-xl"
                    >
                      Register as Family
                    </Button>
                  </Link>
                  <Link to="/provider/signup">
                    <Button
                      type="primary"
                      className="!bg-blue-800 hover:!bg-blue-900 transition-all w-52 h-12 !rounded-xl"
                    >
                      Register as Provider
                    </Button>
                  </Link>
                </div>

                <a
                  className="block mt-10 text-white font-semibold hover:text-white hover:underline border-solid border-t border-blue-400 pt-4"
                  href="https://www.childcareaware.org/about/child-care-aware-of-america/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Visit Child Care Aware® of America
                </a>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer className="footer w-full z-50 bg-white shadow-xl">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center h-full">
          <div className="divide-x divide-[#000] grid grid-cols-[100px_100px_300px] h-5">
            <div className="text-center">
              <a
                className="text-[#000] hover:text-white text-xs"
                href="https://www.childcareaware.org/about/site-terms/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Site Terms
              </a>
            </div>
            <div className="text-center">
              <a
                className="text-[#000] hover:text-white text-xs"
                href="https://www.childcareaware.org/about/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </div>
            <div className="text-center">
              <span className="text-[#000] text-xs">Copyright 2023 Child Care Aware® of America</span>
            </div>
          </div>
          <div className="flex justify-between space-x-3 h-7">
            <a
              className="text-[#000] hover:text-white text-[28px]"
              href="https://www.facebook.com/ChildCareAware/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebookF />
            </a>
            <a
              className="text-[#000] hover:text-white text-[28px]"
              href="https://twitter.com/childcareaware"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaTwitter />
            </a>
            <a
              className="text-[#000] hover:text-white text-[28px]"
              href="https://www.youtube.com/channel/UCeaTKlEpRfc00TJ537v0RZg"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaYoutube />
            </a>
            <a
              className="text-[#000] hover:text-white text-[28px]"
              href="https://www.linkedin.com/company/childcareaware/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
            <a
              className="text-[#000] hover:text-white text-[28px]"
              href="https://www.instagram.com/childcareawareusa/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </Footer>
    </Layout>
  );
}
