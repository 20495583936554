import React from 'react';
import { Input, Button, message, Modal, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import toLower from 'lodash/toLower';

import { actions } from 'features/auth';
import userService from 'services/users.service';
import { selectors } from 'features/auth';
import { passwordValidator } from 'utils';
import Form from 'components/Form';
import FormField from 'components/Authentication/FormField';
import Space, { Spacer } from 'components/Space/Space';
export default function ChangeUserName({ setVisible }) {
  const currentUser = useSelector(selectors.selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const onConfirmation = () => {
    dispatch(actions.logout.pending());
    history.push('/');
  };

  const changeUsername = useAsyncCallback(async (values) => {
    return Auth.signIn(currentUser.email, values.password)
      .then(() =>
        userService
          .changeUserName({ currentEmail: currentUser.email, newEmail: toLower(values.newEmail) })
          .then(async () => {
            setVisible(false);
            Modal.success({
              title: 'Please check your email',
              content: (
                <div>We've sent a temporary password to your email address. Please re-login using that password.</div>
              ),
              onOk: () => onConfirmation(),
              onCancel: () => onConfirmation(),
            });
          })
          .catch((err) => {
            message.error(err?.apierror?.message || 'Something went wrong.');
          }),
      )
      .catch((err) => {
        message.error('Password is incorrect.');
      });
  });

  return (
    <>
      <Form onFinish={changeUsername.execute} data-testid="change-username-form" layout="vertical">
        <>
          <FormField
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Password is required' }, passwordValidator]}
          >
            <Input type="password" placeholder="Password" />
          </FormField>

          <FormField
            label="New Username"
            name="newEmail"
            rules={[
              { required: true, message: 'Username is required.' },
              { type: 'email', message: 'Username is invalid.' },
              () => ({
                validator(rule, value) {
                  if (value && toLower(value) === toLower(currentUser?.email)) {
                    return Promise.reject('New username cannot be same as old username.');
                  } else return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="New Username" />
          </FormField>

          <Divider />

          <Space>
            <Spacer />

            <Button
              disabled={changeUsername.loading}
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button loading={changeUsername.loading} disabled={changeUsername.loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </>
      </Form>
    </>
  );
}
