import React, { memo, useEffect, useMemo } from 'react';
import { Button, Input, message, Spin, Row, Col, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { useParams } from 'react-router-dom';

import Html from 'Html';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import CheckboxField from 'components/Switch/CheckboxField';
import { commonService } from 'services';
import { getBooleanRequiredValidator } from 'utils';
import providerService from 'services/providers.service';
import { actions as centerActions } from 'features/add-center';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import { ADD_FACILITY_STEPS, FACILITY_DETAILS_TABS } from 'constants/index';
import ScrollableTermsView from 'components/ScrollableTermsView/ScrollableTermsView';
import Card from 'components/Card/Card';

const { Paragraph } = Typography;

function Agreement({
  applicationSubmitted,
  onCancel,
  setStep,
  setTab,
  setSubmitted,
  center,
  loading,
  isFormSubmitting,
}) {
  const { step } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isSubmitDisabled = useProviderEditDisabled();
  const isEnabledAgreementCheckBox = useMemo(
    () => center?.application?.agreementAccepted,
    [center?.application?.agreementAccepted],
  );

  const categoryIds = useMemo(
    () => (center?.application?.programCategories || []).map((c) => c.id).join(','),
    [center?.application?.programCategories],
  );
  const { result: agreements = [], loading: loadingAgreement } = useAsync(async () => {
    return await commonService.get(`/common/agreements/provider?categoryIds=1`);
  }, [categoryIds, step]);

  const { result: acceptedAgreements = [], execute: fetchApplicationAgreements } = useAsync(async () => {
    if (center.application?.id) {
      const agreements = await providerService.getApplicationAgreements(center.application?.id);
      return agreements.reduce((prev, curr) => {
        prev[curr.ula.id] = true;
        return prev;
      }, {});
    }
    return {};
    // step dependency is required in order to refetch agreement list
  }, [center.application?.id, step]);

  const isChecked = useMemo(
    () => center.application?.status?.status?.title !== 'INCOMPLETE',
    [center.application?.status?.status],
  );

  useEffect(() => {
    form.setFieldsValue({
      agreementAccepted: agreements.reduce((prev, curr) => prev && Boolean(acceptedAgreements[curr.id]), true),
      agreementAcceptedBy: center.application?.agreementAcceptedBy,
    });
  }, [acceptedAgreements, agreements, center.application, form, isChecked]);

  return (
    <>
      <Card title="Acknowledgement and Agreement" data-testid="agreementCard">
        <Spin spinning={isFormSubmitting || loading || loadingAgreement}>
          <Form
            layout="vertical"
            validateFirst
            onFinish={async (values) => {
              try {
                dispatch(centerActions.setIsFormSubmitting(true));

                // update `agreementAcceptedBy` in the application before submitting
                const updatedCenterData = await providerService.updateCenter(
                  {
                    application: {
                      agreementAcceptedBy: values.agreementAcceptedBy,
                    },
                  },
                  center?.id,
                );
                dispatch(centerActions.setCenterInfo(updatedCenterData));

                const allAgreementIds = agreements.map((agr) => agr.id);
                const agreementIds = allAgreementIds.filter((id) => !acceptedAgreements[id]);
                if (agreementIds.length > 0) {
                  await providerService.acceptAgreement(center.application.id, {
                    applicationId: center.application.id,
                    agreementIds,
                  });
                  fetchApplicationAgreements();
                }
                if (center?.application?.status?.status?.title === 'INCOMPLETE') {
                  await providerService.submitApplication(center.application.id);
                }
                setSubmitted(true);
                setStep(ADD_FACILITY_STEPS.SUBMITTED);
                setTab(FACILITY_DETAILS_TABS.PROGRAM);
              } catch (error) {
                newrelic.noticeError(error);

                console.log('Unable to submit application', error);
                message.error('Unable to submit application');
              } finally {
                dispatch(centerActions.setIsFormSubmitting(false));
              }
            }}
            form={form}
            disabled={applicationSubmitted}
            id="agreementForm"
          >
            <Spin spinning={loadingAgreement}>
              <ScrollableTermsView>
                {agreements.map(({ id, name, content }) => (
                  <div key={id} className="mb-6">
                    <p className="text-center uppercase text-lg font-medium mb-5">{name}</p>
                    <Html html={content} />
                  </div>
                ))}
              </ScrollableTermsView>

              <FormItem
                name="agreementAccepted"
                valuePropName="checked"
                rules={[getBooleanRequiredValidator('You must agree to the Terms and Conditions.')]}
                className="mt-6"
                data-testid="agreementCheckBox"
              >
                <CheckboxField
                  required
                  textClassName="text-gray-600"
                  checkboxProps={{ disabled: isEnabledAgreementCheckBox }}
                >
                  <span>I acknowledge and agree to all terms in the above agreement.</span>
                </CheckboxField>
              </FormItem>

              {center?.application?.agreementAcceptedDate && (
                <div>
                  <span>
                    <b className="font-semibold">Accepted Date:</b> {center?.application?.agreementAcceptedDate}
                  </span>
                </div>
              )}
            </Spin>

            <Row gutter={[20, 0]} className="form-row">
              <Col xs={24} sm={22} md={12}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your full name for the record.',
                    },
                  ]}
                  name="agreementAcceptedBy"
                  label="Your full name for the record"
                  data-testid="nameField"
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
      <Card title="Ready to submit?" className="mt-8" data-testid="submissionCard">
        <Paragraph>
          Review your application carefully before submitting. After you submit, you will need to email Provider Services to make changes.
        </Paragraph>
        <div className="actions flex flex-col-reverse md:flex-row justify-end items-center mt-5">
          <Button
            disabled={false}
            onClick={() => setStep(ADD_FACILITY_STEPS.DOCUMENTS)}
            style={{ borderRadius: '2px' }}
            data-testid="backButton"
          >
            Back
          </Button>

          <div className="flex-grow"></div>

          {!applicationSubmitted && (
            <Button
              type="primary"
              htmlType="submit"
              disabled={isFormSubmitting || isSubmitDisabled}
              loading={isFormSubmitting}
              style={{ borderRadius: '2px' }}
              form="agreementForm"
              data-testid="applyButton"
            >
              Apply
            </Button>
          )}
        </div>
      </Card>
    </>
  );
}

export default memo(Agreement);
