import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { hasPermission, SnapshotContext } from 'utils';
import CareFacilityProgram from 'containers/Provider/AddCenter/CareFacilityProgram';
import BusinessInfo from 'containers/Provider/AddCenter/Tabs/BusinessInfo';
import CenterDetails from 'containers/Provider/AddCenter/Tabs/CenterDetails';
import RatesAndDiscounts from 'containers/Provider/AddCenter/Tabs/RateAndDiscounts';
import AdditionalDocuments from 'containers/Provider/AddCenter/Tabs/AdditionalDocuments';
import Agreement from 'containers/Provider/AddCenter/Agreement';
import { selectors as centerSelectors, actions as centerActions } from 'features/add-center';
import { selectActiveRole } from 'features/auth';
import { ENTITY_TYPES, FACILITY_DETAILS_TABS, ROLES } from 'constants/index';

import styles from './ApplicationReview.module.less';
import addCenterStyles from 'containers/Provider/AddCenter/AddCenter.module.less';
import CaseCoordinatorActivity from '../CaseCoordinatorActivity';
import ProviderChecklist from 'components/Checklist/ProviderChecklist';
import { Button, Drawer, Tabs } from 'antd';
import Card from 'components/Card/Card';
import Space from 'components/Space/Space';
import { ListCheckedMirror } from '@carbon/icons-react';

export default function ApplicationReview({ loading: centerLoading, rootRef }) {
  const activeRole = useSelector(selectActiveRole);
  const roles = useMemo(() => [activeRole], [activeRole]);

  let { id, tab = FACILITY_DETAILS_TABS.PROGRAM } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const center = useSelector(centerSelectors.selectCenterState);
  const isFormSubmitting = useSelector(centerSelectors.selectIsFormSubmitting);

  const requiredRoles = useMemo(() => [ROLES.PROVIDER, ROLES.PROVIDER_COORDINATOR, ROLES.PROVIDER_MANAGER], []);

  const facilityDetailsTabVisible = useMemo(
    () => !center?.inHomeProvider && hasPermission(roles, requiredRoles),
    [center, requiredRoles, roles],
  );

  const setTab = useCallback(
    (tab) => {
      /* istanbul ignore else */
      if (!isFormSubmitting) {
        history.push(`/providers/applications/${id}/review/${tab}`);
      }
    },
    [history, id, isFormSubmitting],
  );
  const commonProps = {
    setTab,
    setStep: (step, tab) => setTab(tab ? tab : step), // adding this to avoid code break due to common code
    onCancel: () => history.push(`/providers/applications/${id}`), // not needed here
    setOpenTabs: () => {},
    dispatch,
    center,
    actions: centerActions,
    isFormSubmitting,
    loading: centerLoading,
    facilityDetailsTabVisible,
  };
  let tabs = [
    {
      label: 'Program',
      key: FACILITY_DETAILS_TABS.PROGRAM,
      children: <CareFacilityProgram {...commonProps} />,
    },
    {
      label: 'Business',
      key: FACILITY_DETAILS_TABS.BUSINESS_INFO,
      children: <BusinessInfo {...commonProps} />,
    },
    // {
    //   label: 'Personnel',
    //   key: FACILITY_DETAILS_TABS.STAFF_INFO,
    //   content: <StaffInfo {...commonProps} id={id} />,
    // },
  ];
  if (facilityDetailsTabVisible) {
    tabs.push({
      label: 'Credentials',
      key: FACILITY_DETAILS_TABS.CENTER_DETAILS,
      children: <CenterDetails {...commonProps} />,
    });
  }
  tabs = tabs.concat([
    // {
    //   label: 'Hours of Operation',
    //   key: FACILITY_DETAILS_TABS.SCHEDULE,
    //   content: <ProviderSchedule setTab={setTab} {...commonProps} />,
    // },
    {
      label: 'Age Groups and Rates',
      key: FACILITY_DETAILS_TABS.DISCOUNTS_INFO,
      children: <RatesAndDiscounts {...commonProps} />,
    },
    {
      label: 'Additional Documents',
      key: FACILITY_DETAILS_TABS.ADDITIONAL_DOCUMENTS,
      children: <AdditionalDocuments {...commonProps} />,
    },
    {
      label: 'Agreement',
      key: FACILITY_DETAILS_TABS.AGREEMENT,
      children: <Agreement {...commonProps} centerLoading={false} setSubmitted={() => {}} />,
    },
  ]);

  const [checklistOpen, setChecklistOpen] = React.useState(false);

  return (
    <div className={classNames(styles.applicationReview, addCenterStyles.center, 'relative')}>
      <Card
        className="bg-gray-100 mb-8"
        noBodyPadding
        title="Provider Application Review"
        extra={
          <Space>
            <Button
              type="primary"
              icon={<ListCheckedMirror className="ml-[-2px] mt-[-1px]" />}
              onClick={() => {
                setChecklistOpen(true);
              }}
            >
              Checklist
            </Button>
          </Space>
        }
      />

      <SnapshotContext.Provider value={{ snapshotSelector: centerSelectors.selectCenterSnapshot }}>
        <Tabs items={tabs} activeKey={tab} onTabClick={setTab} className="mb-5 content-tabs" />
      </SnapshotContext.Provider>

      <CaseCoordinatorActivity
        entity={center}
        entityType={ENTITY_TYPES.CARE_FACILITY}
        className="mt-5 mb-4"
        collapseHeaderClassName="py-4"
      />

      <Drawer
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0 }}
        open={checklistOpen}
        onClose={() => {
          setChecklistOpen(false);
        }}
      >
        <ProviderChecklist className="pb-4" center={center} id={id} isFormSubmitting={isFormSubmitting} />
      </Drawer>
    </div>
  );
}
