import React, { useCallback } from 'react';
import { Form as AntdForm } from 'antd';
import PropTypes from 'prop-types';

function Form({ children, scrollToFirstError, ...restProps }) {
  const onFinishFailed = useCallback(
    ({ errorFields }) => {
      if (!scrollToFirstError) return;

      setTimeout(() => {
        const { name } = errorFields[0];
        const selector = `#${name.join('_')}_help`;
        const _errorHelperNode = document.querySelector(selector);

        // Makes 3 attempts to find an error to scroll to
        if (_errorHelperNode) {
          _errorHelperNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          const _erroredElementNode = document.querySelector(`#${name.join('_')}`);
          if (_erroredElementNode) {
            _erroredElementNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            const _firstErrorOnPage = document.querySelector('.ant-form-item-has-error .ant-form-item-explain-error');

            if (_firstErrorOnPage) {
              _firstErrorOnPage.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }
      }, 100);
    },
    [scrollToFirstError],
  );
  return (
    <AntdForm {...restProps} onFinishFailed={onFinishFailed} scrollToFirstError={false}>
      {children}
    </AntdForm>
  );
}
Form.defaultProps = {
  scrollToFirstError: true,
  preserve: false,
  layout: 'vertical',
  onKeyDown: (e) => (e.name === 'Enter' ? e.preventDefault() : ''),
};
Form.propTypes = {
  scrollToFirstError: PropTypes.bool,
  preserve: PropTypes.bool,
  initialValues: PropTypes.object,
  onFinish: PropTypes.func,
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'inline']),
  onkeydown: PropTypes.func,
};

// Form.useForm = useForm; // AntdForm.useForm;
Form.useForm = AntdForm.useForm;
Form.useWatch = AntdForm.useWatch;
Form.useFormInstance = AntdForm.useFormInstance;
Form.Item = AntdForm.Item;
Form.List = AntdForm.List;
Form.ItemList = AntdForm.ItemList;
Form.ErrorList = AntdForm.ErrorList;
Form.Provider = AntdForm.Provider;

export default Form;

// function useForm() {
//   const [form] = AntdForm.useForm();
//   form.scrollToField = (name) => {
//     const selector = `#${Array.isArray(name) ? name.join('_') : name}`;
//     const node = document.querySelector(selector) || null;
//     if (node) {
//       node.scrollIntoView({ behavior: 'smooth', block: 'center' });
//     }
//   };
//   return [form];
// }
