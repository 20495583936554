import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isSame as comparator, getValue, SnapshotContext } from 'utils';
import classNames from 'classnames';
import Form from 'components/Form';

export default function FormItem({
  name,
  className,
  changeRequestClassName = 'change-request',
  children,
  isSame = comparator,
  formItem = true,
  namePrefix = [],
  getSnapshotValue,
  hideChangeRequestHighlights = false,
  ...props
}) {
  const { snapshotSelector } = useContext(SnapshotContext);
  const snapshot = useSelector(snapshotSelector || (() => null));
  const dependencies = useMemo(() => [name], [name]);
  if (snapshot && !hideChangeRequestHighlights) {
    return (
      <Form.Item dependencies={dependencies} noStyle>
        {(form) => {
          const prevValue = getSnapshotValue
            ? getSnapshotValue(snapshot, name)
            : getValue(snapshot, namePrefix.concat(name));
          const currValue = form.getFieldValue(name);
          if (typeof children === 'function') {
            return children(prevValue, currValue, snapshot, form);
          }
          if (!formItem) {
            return (
              <div
                className={classNames('ant-form-item mb-0', className, {
                  [changeRequestClassName]: !isSame(prevValue, currValue, snapshot, form),
                })}
              >
                {children}
              </div>
            );
          }
          return (
            <Form.Item
              {...props}
              name={name}
              className={classNames(className, {
                [changeRequestClassName]: !isSame(prevValue, currValue, snapshot, form),
              })}
            >
              {children}
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (typeof children === 'function') {
    return children(null, null, null, null);
  }
  if (formItem) {
    return (
      <Form.Item {...props} name={name} className={classNames(className)}>
        {children}
      </Form.Item>
    );
  }
  return children;
}
