import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';

import Form from 'components/Form';

export default function Field({ name, label, initialValue, children, additionalNode, className, ...rest }) {
  return (
    <Col
      {...rest}
      className={`search-field ${className}`}
      onKeyDown={(e) => {
        /* istanbul ignore else */
        if (e.target.id !== 'search-submit-btn' && e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <div className="flex flex-col">
        <label htmlFor={`${name}`}>{label}</label>
        <Form.Item name={name} label={label} initialValue={initialValue} noStyle>
          {children}
        </Form.Item>
      </div>
      {additionalNode}
    </Col>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  additionalNode: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  initialValue: PropTypes.any,
};

Field.defaultProps = {
  className: '',
};
