import { useMemo, useEffect, useCallback } from 'react';
import { Button, Divider, Input, message, Tabs } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory, useLocation } from 'react-router-dom';

import { selectActiveRole } from 'features/auth';
import { actions as familyActions } from 'features/family';
import { actions as providerActions } from 'features/add-center';
import { actions as dashboardActions, selectors as dashboardSelectors } from 'features/dashboard';

import Form from 'components/Form';
import DashboardTable from './DashboardTable';
import BatchAssignment from './BatchAssignment';
import AdvancedSearchForm from './AdvanceSearchForm';
import DashboardFiltersForm from './DashboardFiltersForm';

import useBreakpoints from 'hooks/useBreakpoints';
import { familyService, providerService } from 'services';
import { hasPermission, parseCareFacilityData, parseFamilyData, removeEmptyObjects } from 'utils';
import { DASHBOARD_TYPES, FAMILY_DASHBOARD_COLUMNS, PROVIDER_DASHBOARD_COLUMNS, ROLES } from 'constants/index';
import Card from 'components/Card/Card';
import ClearableWrapper from 'components/ClearableWrapper';
import Space from 'components/Space/Space';
import { Close, Renew } from '@carbon/icons-react';
import EmptyState from 'components/EmptyState/EmptyState';

const PAGE_SIZE = 10;

function ApplicationsDashboard({ advanced, query }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const [form] = Form.useForm();
  const [quickSearchForm] = Form.useForm();
  const [advanceSearchForm] = Form.useForm();

  // Dashboard filters
  const statuses = Form.useWatch('statuses', form);
  const programIds = Form.useWatch('programIds', form);
  const assigned = Form.useWatch('assigned', form) ?? null;
  const assigneeId = Form.useWatch('assigneeId', form) ?? null;
  const highPriorityOnly = Form.useWatch('highPriorityOnly', form) ?? null;

  const activeRole = useSelector(selectActiveRole);
  const roles = useMemo(() => [activeRole], [activeRole]);
  const dashboardState = useSelector(dashboardSelectors.selectDashboardState);

  const onChange = useCallback(
    (pagination, filters, sorter, customParams) => {
      let params = {
        ...pagination,
        page: pagination.current - 1,
      };
      if (sorter) {
        const isNestedValues = Array.isArray(sorter.field);
        const fieldSorter = isNestedValues
          ? sorter.field.reduce((total, current) => `${total}.${current}`)
          : sorter.field;

        if (customParams?.sort) {
          params.sort = customParams.sort;
        } else if (sorter.field && !params.sort) {
          params.sort = `${fieldSorter},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }
      }

      dispatch(dashboardActions.getData.pending({ params: { ...dashboardState.params, ...params } }));
    },
    [dispatch, dashboardState.params],
  );
  const state = useMemo(() => {
    return {
      ...dashboardState,
      onChange,
    };
  }, [dashboardState, onChange]);
  const isFamilyDashbaord = useSelector(dashboardSelectors.selectDashboardType) === DASHBOARD_TYPES.FAMILIES;

  const columns = useMemo(
    () => (isFamilyDashbaord ? FAMILY_DASHBOARD_COLUMNS : PROVIDER_DASHBOARD_COLUMNS),
    [isFamilyDashbaord],
  );

  const apiUrl = useMemo(() => {
    if (query)
      return isFamilyDashbaord ? `/families/applications/search/quick` : `/providers/care-facilities/search/quick`;
    if (advanced) {
      return isFamilyDashbaord
        ? `/families/applications/search/advanced`
        : `/providers/care-facilities/search/advanced`;
    }
    return isFamilyDashbaord ? '/families/applications' : '/providers/care-facilities';
  }, [advanced, isFamilyDashbaord, query]);

  const dashboardFilters = useMemo(() => {
    const filters = {};
    if (statuses?.length) filters.statuses = statuses;
    if (programIds?.length) filters.programIds = programIds;
    if (assigned !== undefined) filters.assigned = assigned;
    if (highPriorityOnly !== undefined) filters.highPriorityOnly = highPriorityOnly;
    if (assigneeId) filters.assigneeId = assigneeId;
    return filters;
  }, [assigned, assigneeId, highPriorityOnly, programIds, statuses]);

  const _filtersAreAcitve = useMemo(() => {
    return Object.values(dashboardFilters).filter(Boolean).length > 0;
  }, [dashboardFilters]);

  const statusLastUpdatedAtIndex = useMemo(() => {
    if (isFamilyDashbaord) {
      return ['status', 'lastModifiedDate'];
    }
    return ['application', 'status', 'lastModifiedDate'];
  }, [isFamilyDashbaord]);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: `${statusLastUpdatedAtIndex.join('.')},asc`,
      ...(query || advanced ? { query } : dashboardFilters),
    }),
    [advanced, dashboardFilters, query, statusLastUpdatedAtIndex],
  );
  const fetchData = useCallback(
    (data) => {
      dispatch(dashboardActions.getData.pending({ url: apiUrl, params, method: advanced ? 'post' : 'get', ...data }));
    },
    [advanced, apiUrl, dispatch, params],
  );

  useEffect(() => {
    if (!advanced) {
      fetchData({});
    } else {
      dispatch(dashboardActions.getData.pending({ cancelled: true }));
      dispatch(dashboardActions.setData([]));
      dispatch(dashboardActions.setLoading(false));
    }
  }, [advanced, apiUrl, dispatch, fetchData, params]);

  useEffect(() => {
    return () => {
      dispatch(dashboardActions.toggleBatchAssign(false));
    };
  }, [dispatch]);

  const mapper = useMemo(() => (isFamilyDashbaord ? parseFamilyData : parseCareFacilityData), [isFamilyDashbaord]);

  const reload = useCallback(async () => {
    fetchData({});
  }, [fetchData]);

  const onSearch = useCallback(
    (query) => {
      if (query) {
        const params = {
          sort: `${statusLastUpdatedAtIndex.join('.')},asc`,
          query,
          current: 1,
          pageSize: PAGE_SIZE,
        };
        const url = isFamilyDashbaord
          ? `/families/applications/search/quick`
          : `/providers/care-facilities/search/quick`;
        dispatch(dashboardActions.getData.pending({ url, method: 'get', params, body: {} }));
        history.push(`${isFamilyDashbaord ? '/families' : '/providers'}?query=${query ?? ''}`);
      } else {
        history.push(`${isFamilyDashbaord ? '/families' : '/providers'}`);
      }
    },
    [dispatch, history, isFamilyDashbaord, statusLastUpdatedAtIndex],
  );
  const { loading: pinApplicationLoading, execute: pinApplication } = useAsyncCallback(
    async (record) => {
      let setPriority = providerService.setApplicationPriority;
      const payload = { id: record?.application?.id || record?.id, highPriority: !Boolean(record.highPriority) };
      try {
        if (
          hasPermission(roles, [
            ROLES.FAMILY_COORDINATOR,
            ROLES.FAMILY_MANAGER,
            ROLES.ER_MANAGER,
            ROLES.FAMILY_QC_MANAGER,
            ROLES.ER_MANAGER,
          ])
        ) {
          setPriority = familyService.setApplicationPriority;
        }
        await setPriority(payload.id, payload.highPriority);
        await reload();
      } catch (error) {
        message.error({
          content: 'Unable to set priority',
          duration: 5,
        });
        newrelic.noticeError(error);
      }
    },
    [reload, roles],
  );

  const batchAssignCount = useMemo(() => state.selectedRowKeys?.length ?? 0, [state.selectedRowKeys]);

  const _noResults = state.data?.length === 0 && !state.loading;

  const clearAll = useCallback(() => {
    form.resetFields();
    quickSearchForm.resetFields();
    advanceSearchForm.resetFields();
  }, [advanceSearchForm, form, quickSearchForm]);

  return (
    <div className={classNames('mt-2', 'min-h-min')}>
      {!query && (
        <Card
          title="Filters"
          extra={
            _filtersAreAcitve ? (
              <Button
                danger
                icon={<Close size={19} className="ml-[-4px] mr-[-4px]" />}
                data-testid="clear-all-filters"
                onClick={clearAll}
              >
                Clear filters
              </Button>
            ) : undefined
          }
        >
          {!(query || advanced) && <DashboardFiltersForm form={form} isFamilyDashbaord={isFamilyDashbaord} />}

          {advanced && (
            <>
              <Divider />

              <AdvancedSearchForm
                form={advanceSearchForm}
                state={state}
                isFamilyDashbaord={isFamilyDashbaord}
                onSubmit={(data) => {
                  let body = {};
                  if (isFamilyDashbaord) {
                    const payload = {
                      ...data,
                      [data.personType || 'applicant']: {
                        ...data.person,
                      },
                    };
                    delete payload.person;
                    delete payload.personType;
                    body = payload;
                  } else {
                    body = removeEmptyObjects(data);
                  }
                  const params = {
                    sort: `${statusLastUpdatedAtIndex.join('.')},asc`,
                    current: 1,
                    pageSize: PAGE_SIZE,
                  };
                  dispatch(dashboardActions.getData.pending({ url: apiUrl, method: 'post', params, body }));
                }}
              />
            </>
          )}
        </Card>
      )}

      <Card
        className="mt-6"
        noBodyPadding
        title={
          advanced ? (
            <p data-testid="search-heading">
              {_noResults ? (
                <Space>
                  <span className="text-xs mb-[-1px]">❌</span> No results
                </Space>
              ) : (
                <Space size={1}>
                  <span>{state.pagination?.total || 0}</span>result{state.pagination?.total !== 1 ? 's' : ''}
                </Space>
              )}
            </p>
          ) : (
            <Form onFinish={({ query }) => onSearch(query)} initialValues={{ query }} form={quickSearchForm}>
              <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-3">
                <Form.Item name="query" className="w-full sm:w-96 mb-0">
                  <ClearableWrapper
                    onChange={(value) => {
                      if (!value) {
                        onSearch(value);
                      }
                    }}
                    clearIconClassName="!right-9 z-10"
                  >
                    <Input.Search
                      placeholder={
                        isFamilyDashbaord
                          ? 'Search by app ID, family ID or sponsor name'
                          : 'Search by provider ID or business name'
                      }
                      onSearch={() => quickSearchForm.submit()}
                    />
                  </ClearableWrapper>
                </Form.Item>
              </div>
            </Form>
          )
        }
        extra={
          <Space>
            {!_noResults && (
              <Button
                disabled={batchAssignCount === 0}
                onClick={() => {
                  dispatch(dashboardActions.toggleBatchAssign());
                }}
                data-testid="batch-assign-btn"
              >
                {batchAssignCount > 1 ? 'Batch Assign' : 'Assign'}
              </Button>
            )}

            <Button
              icon={<Renew />}
              disabled={state.loading}
              loading={state.loading}
              onClick={() => {
                reload();
              }}
              data-testid="reload-btn"
            />
          </Space>
        }
      >
        {state.data ? (
          !_noResults ? (
            <DashboardTable
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (isFamilyDashbaord) {
                      dispatch(familyActions.setApplication({}));
                      history.push(`/families/${record.householdId}/applications/${record.id}`);
                    } else {
                      dispatch(providerActions.setCenterInfo({}));
                      history.push(`/providers/applications/${record.id}`);
                    }
                  },
                };
              }}
              breakpoints={breakpoints}
              colKeys={columns}
              state={state}
              isFamilyDashbaord={isFamilyDashbaord}
              loading={pinApplicationLoading}
              pinApplication={pinApplication}
              mapper={mapper}
            />
          ) : _filtersAreAcitve ? (
            <EmptyState description="No data found, try clearing filters">
              <Button onClick={clearAll} className="m-auto mt-6">
                Clear filters
              </Button>
            </EmptyState>
          ) : null
        ) : null}
      </Card>

      <BatchAssignment
        state={state}
        roles={roles}
        isFamilyDashbaord={isFamilyDashbaord}
        reload={reload}
        visible={state.showBatchAssign}
        setVisible={() => dispatch(dashboardActions.toggleBatchAssign())}
      />
    </div>
  );
}

export default function Dashboard({ match, history }) {
  const { path } = match;
  let { search } = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(search);
  const query = params.get('query');
  const isFamilyDashbaord = useSelector(dashboardSelectors.selectDashboardType) === DASHBOARD_TYPES.FAMILIES;

  const DASHBOARD_CATEGORIES = useMemo(
    () => ({
      ADVANCED_SEARCH: `${isFamilyDashbaord ? '/families' : '/providers'}/search`,
      APPLICATIONS: `${isFamilyDashbaord ? '/families' : '/providers'}`,
    }),
    [isFamilyDashbaord],
  );
  const activeKey = useMemo(() => {
    if (path === '/families/search' || path === '/providers/search') {
      return DASHBOARD_CATEGORIES.ADVANCED_SEARCH;
    }
    return DASHBOARD_CATEGORIES.APPLICATIONS;
  }, [DASHBOARD_CATEGORIES.ADVANCED_SEARCH, DASHBOARD_CATEGORIES.APPLICATIONS, path]);

  useEffect(() => {
    if (path.startsWith('/families')) {
      dispatch(dashboardActions.setDashboardType(DASHBOARD_TYPES.FAMILIES));
    } else if (path.startsWith('/providers')) {
      dispatch(dashboardActions.setDashboardType(DASHBOARD_TYPES.PROVIDERS));
    }
  }, [dispatch, path]);

  const tabs = useMemo(
    () => [
      {
        label: `${isFamilyDashbaord ? 'Family' : 'Provider'} Applications`,
        key: DASHBOARD_CATEGORIES.APPLICATIONS,
        children: <ApplicationsDashboard query={query} isFamilyDashbaord={isFamilyDashbaord} />,
      },
      {
        label: `Advanced Search`,
        key: DASHBOARD_CATEGORIES.ADVANCED_SEARCH,
        children: <ApplicationsDashboard advanced isFamilyDashbaord={isFamilyDashbaord} />,
      },
    ],
    [DASHBOARD_CATEGORIES.ADVANCED_SEARCH, DASHBOARD_CATEGORIES.APPLICATIONS, isFamilyDashbaord, query],
  );

  const setTab = useCallback((key) => history.push(`${key}`), [history]);

  return <Tabs items={tabs} activeKey={activeKey} onTabClick={setTab} className="mb-5" destroyInactiveTabPane />;
}
