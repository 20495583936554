import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Select from 'react-select/async';
import { components as rsComponents } from 'react-select';
import commonApi from 'services/common.service';
import { USA_STATES_DROPDOWN_OPTIONS } from 'constants';
import { cloneDeep } from 'lodash';
const { IndicatorSeparator, DropdownIndicator } = rsComponents;

export default function FormSelect({
  id,
  name,
  value,
  optionsApiUrl,
  apiPrefix,
  getOptions = commonApi.get,
  ariaLabel,
  onChange,
  hideDropdownIcon,
  disabled,
  hideSeparator,
  isStringOptions,
  onChangeFormatter,
  getOptionValue,
  getOptionLabel,
  isOptionSelected,
  isOptionDisabled,
  components,
  isSearchable = true,
  readOnly,
  menuIsOpen,
  isClearable,
  inputProps,
  sorter,
  isMulti,
  optionFilter,
  optionFormatter,
  ...rest
}) {
  const [options, setOptions] = useState([]);
  const values = isMulti
    ? options.filter((opt) => isOptionSelected(opt, value || []))
    : options.find((opt) => isOptionSelected(opt, [value]));

  const formattedOptions = useMemo(() => {
    let _result = cloneDeep(options || []);
    if (optionFormatter) {
      _result = optionFormatter(_result);
    }

    return _result.filter(optionFilter);
  }, [options, optionFilter, optionFormatter]);

  return (
    <Select
      key={value}
      {...rest}
      aria-label={ariaLabel}
      classNamePrefix="select"
      isSearchable={isSearchable || !readOnly}
      isClearable={readOnly ? !readOnly : isClearable}
      menuIsOpen={readOnly ? !readOnly : menuIsOpen}
      inputProps={{ ...inputProps, readonly: readOnly }}
      readOnly={readOnly}
      inputId={id}
      id={`select-container-${id}`}
      name={name}
      menuPlacement="auto"
      cacheOptions
      defaultOptions
      options={formattedOptions}
      value={values}
      onChange={(value) => {
        onChange(value ? onChangeFormatter(value) : value);
      }}
      getOptionLabel={(opt) => {
        const icon = opt.icon;
        const label = getOptionLabel ? getOptionLabel(opt) : opt.label;

        if (!icon) {
          return label;
        }

        return (
          <div className="flex items-center gap-1">
            {icon}

            <span>{label}</span>
          </div>
        );
      }}
      getOptionValue={getOptionValue}
      isOptionSelected={isOptionSelected}
      isMulti={isMulti}
      isDisabled={disabled}
      components={{
        ...(components || {}),
        IndicatorSeparator: (props) => {
          if (hideSeparator) return null;
          if (components?.IndicatorSeparator) {
            return <components.IndicatorSeparator {...props} />;
          }
          return <IndicatorSeparator {...props} />;
        },
        DropdownIndicator: (props) => {
          if (hideDropdownIcon) return null;
          if (components?.DropdownIndicator) {
            return <components.DropdownIndicator {...props} />;
          }
          return <DropdownIndicator {...props} />;
        },
      }}
      loadOptions={async (searchInput) => {
        try {
          if (options.length === 0 && optionsApiUrl.indexOf('undefined') === -1) {
            let data;
            if (optionsApiUrl === '/countries/states/US') {
              /*
               * If requesting USA states, use local list rather than API endpoint
               */
              data = USA_STATES_DROPDOWN_OPTIONS;
            } else {
              data = await getOptions(`${apiPrefix}${optionsApiUrl}`, { cache: { interpretHeader: false } });
            }

            let items = [];
            if (isStringOptions) {
              items = data.map((opt) => ({ label: opt, value: opt }));
            } else {
              items = data;
              if (typeof sorter === 'function') {
                items = items.sort(sorter);
              }
            }
            items = Array.from(items || []);
            if (typeof isOptionDisabled === 'function') {
              items = items.map((opt) => ({ ...opt, isDisabled: isOptionDisabled(opt) }));
            }

            items = optionFormatter ? optionFormatter(items) : items;
            setOptions(items);

            return items;
          } else {
            return options.filter((opt) => {
              let _label = getOptionLabel(opt);
              if (typeof _label !== 'string') {
                _label = opt.title || '';
              }

              return _label?.toLowerCase()?.indexOf(searchInput?.toLowerCase()) > -1;
            });
          }
        } catch (error) {
          newrelic.noticeError(error);
          return [];
        }
      }}
      styles={{
        control: (base) => ({
          ...base,
          height: 32, // Set the height you want here
          minHeight: 32,
          borderRadius: '2px',
        }),
        valueContainer: (base) => ({
          ...base,
          height: 32,
          padding: '0 6px',
        }),
        input: (base) => ({
          ...base,
          margin: 0,
          padding: 0,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          height: 32,
        }),
        ...rest.styles,
      }}
    />
  );
}

FormSelect.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  optionsApiUrl: PropTypes.string.isRequired,
  apiPrefix: PropTypes.string,
  placeholder: PropTypes.any,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isStringOptions: PropTypes.bool,
  getOptions: PropTypes.func,
  onChange: PropTypes.func,
  onChangeFormatter: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isOptionSelected: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  value: PropTypes.any,
  styles: PropTypes.any,
  theme: PropTypes.any,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
};

FormSelect.defaultProps = {
  isClearable: true,
  readOnly: false,
  menuIsOpen: undefined,
  disabled: false,
  isMulti: false,
  isStringOptions: false,
  apiPrefix: '/providers',
  onChangeFormatter: (v) => v,
  getOptionValue: (v) => v.id,
  getOptionLabel: (opt) => opt.title,
  isOptionSelected: (option, values) => {
    return values.find((val) => val?.id === option.id);
  },
  optionFilter: () => true,
};
