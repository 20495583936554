/**
 * This module contains common functions for redux (actions, sagas, reducers) related things
 */
import React from 'react';
import dayjs from 'dayjs';
import Axios from 'axios';
import produce from 'immer';
import classNames from 'classnames';
import { Storage, Auth } from 'aws-amplify';
import { createAction } from '@reduxjs/toolkit';
import { message as antMessage, Input, Space, Button, Tooltip } from 'antd';
import isEqual from 'lodash/isEqual';
import lodashMerge from 'lodash/merge'; // lodash/get is not getting nested keys
import setNestedValue from 'lodash/set';
import isNil from 'lodash/isNil';
import transform from 'lodash/transform';
import { IoFunnelSharp } from 'react-icons/io5';

import {
  SIMPLE_PHONE_NUMBER_REGEX,
  FORMATTED_PHONE_NUMBER_REGEX,
  PHONE_NUMBER_EXT_REGEX,
  DATE_FORMAT,
  TIME_FORMAT,
  CLIENT_DATE_TIME_FORMAT,
  ROLES,
  YEAR_TO_WEEKS,
  MONTH_TO_WEEKS,
} from 'constants/index';
import providerService from 'services/providers.service';
import filesService from 'services/files.service';
import userService from 'services/users.service';
import config from 'config';
import DatePicker from 'components/DatePicker';
import Select from 'components/Select';
import { actions as centerActions } from 'features/add-center';
import { actions as documentActions } from 'features/documents';
import { Home, InHome, FamilyGroup, SchoolChildCare, ReligiousCareFacility } from 'utils/icons';
import ColumnTitle from 'components/ColumnTitle';
import { AiFillCaretDown } from 'react-icons/ai';

class CancelToken {
  constructor(initialValue) {
    this.source = initialValue;
  }
  getSource() {
    return this.source;
  }
  setSource(value) {
    this.source = value;
  }
  cancel() {
    this.source.cancel();
  }
}

export const cancelSource = new CancelToken(Axios.CancelToken.source());

export const sleep = (time, resolve = true, message) =>
  new Promise((res, rej) => {
    setTimeout(resolve ? res : () => rej({ message: message || 'Error' }), time);
  });

export const getValue = (data, key, defaultValue = undefined) =>
  (Array.isArray(key) ? key : [key]).reduce((p, c) => (p && p[c]) || defaultValue, data);

export const dayjsToValues = (dayjsObj) => {
  return {
    day: dayjsObj.date(),
    month: dayjsObj.month() + 1,
    year: dayjsObj.year(),
  };
};

export const valuesToDayjs = (values) => {
  if (!values) return null;
  return dayjs(new Date(values.year, values.month - 1, values.day));
};

export const dateRangeToString = (dateRange, format = 'MMM DD') => {
  const { from, to } = dateRange || { from: null, to: null };
  const start = valuesToDayjs(from);
  const end = valuesToDayjs(to);
  let date = start ? start.format(format) : '';
  if (start && end) {
    date += ` - ${end.format(format)}`;
  }
  return date;
};
export const stringToDateRange = (input, year = dayjs().year()) => {
  if (input.indexOf(' - ') === -1) return { from: null, to: null };
  const [from, to] = input.split(' - ');
  return {
    from: dayjsToValues(dayjs(`${from} ${year}`)),
    to: dayjsToValues(dayjs(`${to} ${year}`)),
  };
};

export const parseQueryParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    let value;
    if (params.getAll(key).length > 1) {
      value = params.getAll(key);
    } else {
      value = params.get(key);
    }
    if (value !== undefined) obj[key] = value;
  }
  const dateRange = {};
  if (obj.from) {
    dateRange.from = dayjsToValues(dayjs(obj.from));
  }
  if (obj.to) {
    dateRange.to = dayjsToValues(dayjs(obj.to));
  }
  if (obj.from || obj.to) {
    obj.dateRange = dateRange;
  }
  if (obj.distanceInMiles) {
    obj.distanceInMiles = Number(obj.distanceInMiles);
  } else {
    delete obj.distanceInMiles;
  }
  if (obj.ageInMonths) {
    obj.ageInMonths = Number(obj.ageInMonths);
  } else {
    delete obj.ageInMonths;
  }
  if (obj.facilityTypes) {
    obj.facilityTypes = obj.facilityTypes.split(',');
  } else {
    delete obj.facilityTypes;
  }
  return obj;
};
export const paramsToQueryString = (values, isPublicSearch) => {
  const { dateRange, facilityTypes } = values;
  const params = {
    ...values,
  };
  if (dateRange && dateRange.from) {
    params.from = valuesToDayjs(dateRange.from).format('YYYY-MM-DD');
  }
  if (dateRange && dateRange.to) {
    params.to = valuesToDayjs(dateRange.to).format('YYYY-MM-DD');
  }
  delete params.dateRange;
  if (facilityTypes && facilityTypes.length > 0) {
    params.facilityTypes = facilityTypes.join(',');
  }
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === '') {
      delete params[key];
    }
  });
  if (params.location) {
    params.placeId = params.location.place_id || params.location.placeId || params.placeId;
  }
  if (!params.ageInMonths || params.ageInMonths === 0) {
    delete params.ageInMonths;
  }
  if (!params.distanceInMiles || params.distanceInMiles === 0) {
    delete params.distanceInMiles;
  }
  if (!params.facilityTypes || params.facilityTypes?.length === 0) {
    delete params.facilityTypes;
  }
  delete params.location;
  return new URLSearchParams(params).toString();
};

export const updateQueryParams = (history, key, value) => {
  const { location } = history;
  const search = location.search;
  const pathname = location.pathname;
  const params = new URLSearchParams(search);
  if (key === 'amenities' && (!value || value?.length === 0)) {
    params.delete(key);
  } else if (key === 'price' && value[0] === 0 && value[1] === 0) {
    params.delete(key);
  } else if (key === 'languages' && (!value || value.length === 0)) {
    params.delete(key);
  } else if (key === 'distanceInMiles' && Number(value) === 0) {
    params.delete(key);
  } else if (key === 'ageInMonths' && Number(value) === 0) {
    params.delete(key);
  } else if (key === 'facilityType' && (!value || value.length === 0)) {
    params.delete(key);
  } else if (key === 'facilityTypes' && !value) {
    params.delete(key);
  } else {
    params.set(key, value);
  }
  history.push({
    pathname,
    search: params.toString(),
  });
};

export const parseRange = (value, formatter) => {
  if (!value) return undefined;
  if (value.indexOf(',') > -1) {
    return value.split(',').map(formatter);
  } else {
    return [0, 0];
  }
};

export const concatLatLng = (point) => {
  if (!point) return '';
  return `${point.lat},${point.lng}`;
};
export const getCompletedAddress = (address) => {
  if (!address) return '';
  const addressComponents = [address?.street?.trim?.(), address?.city, address?.state, address?.postalCode];
  if (address?.country !== 'US') {
    addressComponents.push(address.country);
  }
  let fullAddress = addressComponents.filter(Boolean).join(', ');
  return fullAddress.trim();
};

export const message = {
  ...['success', 'error', 'warning', 'info'].reduce((funcs, name) => {
    funcs[name] = (content, options = {}) => {
      const { ariaLive = 'assertive', duration = 1.5, style = {} } = options;
      antMessage[name](
        <div style={{ display: 'inline-block', ...style }} aria-live={ariaLive}>
          {content}
        </div>,
        duration,
      );
    };

    return funcs;
  }, {}),
};

export const passwordValidator = () => ({
  validator(rule, value) {
    if (!value) return Promise.resolve();
    let errors = [];
    if (!/[a-z]+/.test(value)) {
      errors.push('1 lower case letter');
    }
    if (!/[A-Z]+/.test(value)) {
      errors.push('1 upper case letter');
    }
    if (!/\d+/.test(value)) {
      errors.push('1 numeric');
    }
    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      errors.push('1 special character');
    }
    if (value.length < 8) {
      errors.push('8 characters');
    }
    if (errors.length === 0) {
      return Promise.resolve();
    }
    const error = errors.reduce((err, curr, index) => {
      if (index + 1 === errors.length) {
        return `${err} and ${curr}`;
      }
      return `${err}, ${curr}`;
    });
    return Promise.reject(`Password must have ${error}.`);
  },
});

export const breakpointsContext = React.createContext({});
export const LayoutContext = React.createContext({});
export const SnapshotContext = React.createContext({});
export const ScrollContext = React.createContext([false, () => {}, false]);

export const frequencyOptions = [
  {
    title: 'Week',
    id: 'WEEKLY',
  },
  {
    title: 'Month',
    id: 'MONTHLY',
  },
  {
    title: 'Year',
    id: 'YEARLY',
  },
  {
    title: 'Other',
    id: 'OTHER',
  },
];
export const frequencyOptionsMap = {
  MONTHLY: 'Month',
  WEEKLY: 'Week',
  YEARLY: 'Year',
  OTHER: 'Other',
};
export const frequencyWeights = {
  WEEKLY: 7,
  MONTHLY: 30,
  YEARLY: 365,
  ANNUAL: 365,
  OTHER: 0,
};

export const registrationFrequencyOptions = [
  {
    title: 'Annual',
    id: 'ANNUAL',
  },
  {
    title: 'One-Time',
    id: 'ONE_TIME',
  },
  {
    title: 'Other',
    id: 'OTHER',
  },
];

export const ageGroupOptions = [
  ...Array.from({ length: 11 }, (_, i) => i + 1).map((i) => ({
    title: `${i} ${i > 1 ? 'Months' : 'Month'}`,
    id: i,
  })),
  ...Array.from({ length: 13 }, (_, i) => i + 1).map((i) => ({
    title: `${i} ${i > 1 ? 'Years' : 'Year'}`,
    id: i * 12,
  })),
];

export const numberValidator = async (rule, value) => {
  if (value === undefined || value === '') return;
  if (Number.isNaN(Number(value))) {
    throw new Error('Not a valid number.');
  }
  if (value < 0) {
    throw new Error("Value can't be negative number.");
  }
};
export const getMinMaxNumberValidator =
  ({ min, max, minMessage, maxMessage }) =>
  async (rule, value) => {
    if (value === undefined || value === '') return;
    if (Number.isNaN(Number(value))) {
      throw new Error('Not a valid number.');
    }
    if (min && min > value) {
      throw new Error(minMessage || 'Value is too small.');
    }
    if (min && max < value) {
      throw new Error(maxMessage || 'Value is too large.');
    }
  };
export const getNumericValidator = (message) => async (rule, value) => {
  if (value === undefined || value === '') return;
  if (!/^\d+$/.test(value) || value < 0) {
    throw new Error(message);
  }
};

export const getStringValidator = (message) => async (rule, value) => {
  if (/^\d+$/.test(value)) {
    throw new Error(message);
  }
};

export const validateDates = (getFieldValue, startField, endField, errorMessage) => {
  return () => {
    const startDate = getFieldValue(startField);
    const endDate = getFieldValue(endField);

    if (startDate && endDate) {
      if (startDate.isAfter(endDate) || endDate.isBefore(startDate)) {
        return Promise.reject(new Error(errorMessage));
      }
    }
    return Promise.resolve();
  };
};

export const getPastDateValidator = (message = 'Date should be in paste.') => ({
  validator: async (rule, value) => {
    if (!value) return;
    if (dayjs(value).isAfter(dayjs().endOf('d'))) {
      throw new Error(message);
    }
  },
});

export const getDisabledDateValidator =
  (isDisabledDate, message = 'Date is not allowed.') =>
  (form) => ({
    validator: async (rule, value) => {
      if (!value) return;
      if (isDisabledDate(value, form)) {
        throw new Error(message);
      }
    },
  });

export class S3 {
  // static async get(key, { bucket, ...rest } = {}) {
  //   if (!bucket) {
  //     return await Storage.get(key, { ...rest, bucket: 'greatchildcare-artefacts233546-dev' });
  //   }
  //   return await Storage.get(key, { bucket, ...rest });
  // }
  static async get(key, { ...rest } = {}) {
    return await Storage.get(key, { ...rest });
  }
}

export const getTextSorter = (key) => (b, a) => {
  let aValue = getValue(a, key);
  let bValue = getValue(b, key);
  if (aValue && bValue) {
    aValue = aValue?.toLowerCase?.();
    bValue = bValue?.toUpperCase?.();
    return bValue?.localeCompare ? bValue?.localeCompare(aValue) : 0;
  }
  if (aValue && !bValue) return -1;
  if (!aValue && bValue) return 1;

  return 0;
};
export const getBooleanSorter = (key) => (b, a) => {
  const aValue = getValue(a, key);
  const bValue = getValue(b, key);
  if (aValue && !bValue) return -1;
  if (!aValue && bValue) return 1;
  return 0;
};

export const getSorter = (getter) => (a, b) => {
  const aValue = getter(a);
  const bValue = getter(b);
  if (aValue && bValue) return bValue?.localeCompare(aValue);
  if (aValue && !bValue) return -1;
  if (!aValue && bValue) return 1;
  return 0;
};
export const getNumberSorter = (key) => (a, b) => {
  const aValue = getValue(a, key);
  const bValue = getValue(b, key);
  return aValue - bValue;
};

export const getDateSorter =
  (key, nullAtEnd = false, sort = 'asc') =>
  (a, b) => {
    const aValue = getValue(a, key);
    const bValue = getValue(b, key);
    // console.log('🚀 ~ file: index.jsx:468 ~ aValue, bValue:', aValue, bValue);
    if (aValue === bValue) return 0;
    if (aValue && bValue) {
      if (sort === 'acs') {
        return dayjs(aValue).isAfter(dayjs(bValue)) ? 1 : -1;
      }
      return dayjs(aValue).isAfter(dayjs(bValue)) ? -1 : 1;
    }
    if (aValue && !bValue) return nullAtEnd ? -1 : 1;
    if (!aValue && bValue) return nullAtEnd ? 1 : -1;
  };

export const dependencyRequiredValidator =
  (dependencyKey, message, when = (value) => !!value) =>
  ({ getFieldValue }) => {
    return {
      validator(_, value) {
        if (when(getFieldValue(dependencyKey)) && !value) {
          return Promise.reject(message);
        }

        return Promise.resolve();
      },
    };
  };

export function compareValues(formValue, currentSavedValue) {
  if (!isNil(currentSavedValue) && formValue !== currentSavedValue) return 'error';
}
export function isSame(prev, curr) {
  if (prev === curr) return true;
  if (
    (prev === null && curr === undefined) ||
    (prev === undefined && curr === null) ||
    (prev === undefined && curr === '')
  )
    return true;
  if ((prev === undefined || prev === null) && curr === false) return true;
  return false;
}
export function isSameDate(prev, curr) {
  if (prev === curr) return true;
  if (prev === undefined && curr === null) return true;
  if (typeof prev === 'string' && dayjs.isDayjs(curr)) {
    return prev === curr.format(DATE_FORMAT[2]);
  }
  return false;
}

export function copy(obj, keys) {
  return keys.reduce((prev, key) => {
    prev[key] = getValue(obj, key);
    return prev;
  }, {});
}
export function changeRequestClassName(keys, snapshotCollection) {
  return (record, index) => {
    const curr = copy(record, keys);
    const isSame = snapshotCollection?.some((row) => {
      return isEqual(curr, copy(row, keys));
    });

    return classNames('h-10 !border-b  !border-white', {
      'bg-blue-700 bg-opacity-5': isSame,
      'bg-orange-100': !isSame && index % 2 === 0,
      'bg-orange-100 bg-opacity-50': !isSame && index % 2 !== 0,
    });
  };
}
// TODO: NEED TO REVISIT THE LOGIC FOR A COMMON COMPONENT.
// export function compareValues(formValue, currentSavedValue, status) {
//   if (!['INCOMPLETE', 'SUBMITTED'].includes(status) && formValue !== currentSavedValue) return 'error';
// }

export const dependencyNumberValidator =
  (dependencyKey) =>
  ({ getFieldValue }) => {
    return {
      validator: async (_, value) => {
        if (getFieldValue(dependencyKey)) {
          return numberValidator(_, value);
        }
        return Promise.resolve();
      },
    };
  };

export const getBooleanRequiredValidator = (message) => () => {
  return {
    validator(_, value) {
      if (!value) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  };
};
export const getPhoneNumberValidator = (message) => () => {
  return {
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (value.length === 14 && FORMATTED_PHONE_NUMBER_REGEX.test(value)) {
        return Promise.resolve();
      }
      if (value.length === 12 && SIMPLE_PHONE_NUMBER_REGEX.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(message);
    },
  };
};
export const getPhoneEXTValidator = (message) => () => {
  return {
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }

      if (value && PHONE_NUMBER_EXT_REGEX.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(message);
    },
  };
};

export const getMilitaryEmailValidator = (message) => () => {
  return {
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!/\.mil$/.test(value)) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  };
};

export const getCognitoUserInfo = async (usr) => {
  const [cognitoUser, session, dbUser] = await Promise.all([
    Auth.currentUserInfo(),
    Auth.userSession(usr),
    userService.getCurrentUser(),
  ]);
  const groups = session.accessToken.payload['cognito:groups'];
  const attrs = cognitoUser.attributes;
  let user = {
    ...dbUser,
    identityId: cognitoUser.id,
    username: cognitoUser.username,
    firstName: dbUser.firstName || attrs.given_name,
    middleName: '',
    lastName: dbUser.lastName || attrs.family_name,
    phone: attrs.phone_number,
    email: attrs.email,
    groups,
  };
  return user;
};

export const hasPermission = (userRoles, requiredRoles, operator = 'OR') => {
  if (typeof requiredRoles === 'string') {
    return userRoles?.indexOf(requiredRoles) > -1;
  }
  if (Array.isArray(requiredRoles)) {
    return requiredRoles.reduce((allowed, name) => {
      if (operator === 'AND') return allowed && userRoles?.indexOf(name) > -1;
      return allowed || userRoles?.indexOf(name) > -1;
    }, operator === 'AND');
  }
  return false;
};

export const downloadFile = async (file, level) => {
  let image;
  if (file.response?.key) {
    const { Body } = await Storage.get(file.response?.key, {
      level,
      download: true,
    });
    image = Body;
  } else {
    image = await filesService.getS3File(file);
    if (file.type === 'image/svg+xml') {
      // eslint-disable-next-line no-use-before-define
      const image = new Blob([image], { type: 'image/svg+xml' });
    }
  }

  const url = URL.createObjectURL(image);

  // const url = window.URL.createObjectURL(Body);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', file.name);
  link.style.display = 'none';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const uploadFileFieldsFormat = (file, user, documentType) => ({
  ...file,
  lastModifiedDate: file.lastModifiedDate,
  name: file.name,
  size: file.size,
  status: file.status,
  // type: file.type || file.mimeType,
  documentType,
  type: documentType,
  mimeType: file.type || file.mimeType,
  uid: file.uid,
  s3Key: file.s3Key || file.response?.key,
  prefix: `/private/${user.identityId}/`,
  // response: file.response,
  bucket: config.AMPLIFY_CONFIG.aws_user_files_s3_bucket,
});

export const parseUploadData = (values, key, center, userInfo) => {
  if (values[key]) {
    let keys = {};
    if (center?.[key] && center?.[key]?.length > 0) {
      keys = center[key].reduce((prev, curr) => {
        prev[curr.s3Key] = curr;
        return prev;
      }, keys);
    }
    values[key] = values?.[key]?.map((file) => {
      const data = uploadFileFieldsFormat(file, userInfo);
      if (data.s3Key in keys) {
        data.id = keys[data.s3Key].id;
      }
      return data;
    });
  }
  return values;
};

export const getBase64String = async (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64String = reader.result;
      res(base64String);
    };
    reader.onerror = function (e) {
      rej(e);
    };
  });
};

export const b64toBlob = (base64) => fetch(base64).then((res) => res.blob());

export const getFormattedPhoneNumber = (value) => {
  if (!value) return '';
  if (SIMPLE_PHONE_NUMBER_REGEX.test(value)) {
    return `(${value.slice(2, 5)}) ${value.slice(5, 8)}-${value.slice(8, 12)}`;
  }
  value = value.replace(/\D+/g, '');
  /* istanbul ignore else */
  if (/^\d{10}$/.test(value)) {
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }
  /* istanbul ignore else */
  if (/^1\d{10}$/.test(value)) {
    return `(${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
  }
  return value;
};

export const getGooglePlace = async (placeId) => {
  return new Promise((res, rej) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        res(results[0]);
      } else {
        rej({ message: 'Unable to find this place' });
      }
    });
  });
};
export const getPlaceLatLng = (place) => {
  return {
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  };
};

export const mergeRefs =
  (...refs) =>
  (el) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(el);
      } else if (Object(ref) === ref) {
        ref.current = el;
      }
    });
  };

export function keyCodeMatches(event, keysToMatch) {
  for (let i = 0; i < keysToMatch.length; i++) {
    if (keysToMatch[i] === event.which || keysToMatch[i] === event.key) {
      return true;
    }
  }
  return false;
}

// export const clone = lodashClone;
// export const jsonClone = (data) => JSON.parse(JSON.stringify(data));
export const deepClone = (obj) => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    (key) => (clone[key] = typeof obj[key] === 'object' && !dayjs.isDayjs(obj[key]) ? deepClone(obj[key]) : obj[key]),
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export const clone = deepClone;

export const merge = (data1, data2) => lodashMerge(clone(data1), clone(data2));

export const getFilterProps = ({
  ref,
  dataIndex,
  placeholder,
  filterInput,
  onFilter = (value, record) => {
    const index = Array.isArray(dataIndex) ? dataIndex : [dataIndex];
    const itemValue = getValue(record, index);
    return itemValue ? itemValue.toString().toLowerCase().includes(value.toLowerCase()) : '';
  },
  testIdPrefix = '',
}) => {
  const testId = `${testIdPrefix}${Array.isArray(dataIndex) ? dataIndex.join('_') : dataIndex}`;
  return {
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          {filterInput ? (
            filterInput({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              testId,
            })
          ) : (
            <Input
              ref={ref}
              placeholder={placeholder || `Search`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys([e.target.value])}
              style={{ marginBottom: 8, display: 'block' }}
              data-testid={`filter-input-${testId}`}
            />
          )}

          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              // icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              className="focus-highlight"
              data-testid={`filter-search-btn-${testId}`}
            >
              Search
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
              className="focus-highlight"
              data-testid={`filter-filter-btn-${testId}`} // filter-filter is intentional
            >
              Filter
            </Button>
            <Button
              onClick={clearFilters}
              size="small"
              style={{ width: 90 }}
              className="focus-highlight"
              data-testid={`filter-reset-btn-${testId}`}
            >
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Button className="filter-btn" data-testid={`filter-trigger-${testId}`}>
        {filtered ? <IoFunnelSharp className="text-primary" /> : <IoFunnelSharp className="text-gray-300" />}
      </Button>
    ),
    onFilter,
    onFilterDropdownVisibleChange: (visible) => {
      /* istanbul ignore else */
      if (visible) {
        setTimeout(() => ref?.current?.focus(), 100);
      }
    },
  };
};

export const getDatePickerFilterProps = ({ ref, dataIndex, placeholder, testIdPrefix = '', onFilter }) =>
  getFilterProps({
    ref,
    dataIndex,
    testIdPrefix,
    onFilter:
      onFilter ||
      ((value, record) => {
        const currentValue = getValue(record, Array.isArray(dataIndex) ? dataIndex : [dataIndex]);
        return (
          value && currentValue && dayjs.utc(currentValue).local().format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
        );
      }),
    // eslint-disable-next-line react/prop-types
    filterInput: function filterInput({ setSelectedKeys, selectedKeys, confirm, clearFilters, testId }) {
      return (
        <DatePicker
          ref={ref}
          autoFocus
          data-testid={`datepicker-input-${testId}`}
          placeholder={placeholder || `Search Date`}
          value={selectedKeys[0]}
          onChange={(date) => {
            setSelectedKeys(date ? [date] : []);
          }}
          id={testId}
          style={{ marginBottom: 8, display: 'block' }}
          // getPopupContainer={() => document.querySelector('#new-applications-dashboard')}
        />
      );
    },
  });

export const getSelectFilterProps = ({
  ref,
  dataIndex,
  options,
  placeholder,
  loading = false,
  testIdPrefix = '',
  onFilter,
  onChangeFormatter = (v) => v,
  getOptionValue = (v) => v.id,
  getOptionLabel = (opt) => opt.title,
  isOptionSelected = (option, [value]) => option?.id === value?.id,
  isOptionDisabled = () => false,
}) =>
  getFilterProps({
    ref,
    dataIndex,
    testIdPrefix,
    onFilter,
    // eslint-disable-next-line react/prop-types
    filterInput: function filterInput({ setSelectedKeys, selectedKeys, confirm, clearFilters, testId }) {
      return (
        <Select
          ref={ref}
          options={options}
          onChangeFormatter={onChangeFormatter}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          isOptionSelected={isOptionSelected}
          isOptionDisabled={isOptionDisabled}
          placeholder={placeholder || 'Select'}
          value={selectedKeys[0]}
          onChange={(date) => {
            setSelectedKeys(date ? [date] : []);
          }}
          className="mb-2"
          id={`select-${testId}`}
          inputId={`select-input-${testId}`}
          data-testid={`filter-select-${testId}`}
          loading={loading}
        />
      );
    },
  });

export function createAsyncAction(prefix) {
  const action = createAction(`${prefix}/pending`);
  action.pending = createAction(`${prefix}/pending`);
  action.fulfilled = createAction(`${prefix}/fulfilled`);
  action.rejected = createAction(`${prefix}/rejected`);
  return action;
}

export const formatDates = (data, formatKeys = [], format = 'YYYY-MM-DD') => {
  if (!formatKeys || formatKeys?.length === 0) {
    return data;
  }
  return produce(data, (draft) => {
    formatKeys.forEach((key) => {
      const value = getValue(draft, key);
      if (dayjs.isDayjs(value)) {
        setNestedValue(draft, key, value.format(format));
      } else if (dayjs(value, DATE_FORMAT, 'es', true).isValid()) {
        setNestedValue(draft, key, dayjs(value).format(format));
      }
    });
  });
};

export const formatDate = (dateString, format = DATE_FORMAT[1], fallback = 'N/A') => {
  const date = dayjs(dateString);
  return dateString && date.isValid() ? date.format(format) : fallback;
};
export const formatDateTimeToLocal = (dateTimeString, format = CLIENT_DATE_TIME_FORMAT, fallback = 'N/A') => {
  const date = dayjs.utc(dateTimeString);
  return dateTimeString && date.isValid() ? date.local().format(format) : fallback;
};

export const formatTo24HourTime = (time, defaultValue = null) => {
  if (time) {
    return dayjs(time, TIME_FORMAT.HOURS_MINUTES_AM_PM).format(TIME_FORMAT.HOURS_MINUTES);
  }
  return defaultValue;
};

export const calculateTiming = (d) => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;
  while (d) {
    if (d >= 365) {
      years++;
      d -= 365;
    } else if (d >= 30) {
      months++;
      d -= 30;
    } else if (d >= 7) {
      weeks++;
      d -= 7;
    } else {
      days++;
      d--;
    }
  }
  return {
    years,
    months,
    weeks,
    days,
  };
};

const ICONS = {
  'Family/Group Child Care Home': FamilyGroup,
  'Child Care Center': Home,
  'School Child Care Facility': SchoolChildCare,
  'Religious Or Other Facility': ReligiousCareFacility,
  'In-Home Child Care': InHome,
};

export const CenterTypeInput = ({ centerType, title, className, iconClassName }) => {
  const Icon = ICONS[title] || Home;
  return (
    <div className={classNames('center-type rounded-full shadow-sm', className)} id={centerType}>
      <div className="center-box flex justify-center items-center" key={title}>
        <Icon className={classNames('w-40 h-40', iconClassName)} />
      </div>
    </div>
  );
};

export function htmlToText(html) {
  // Create a new div element
  var tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
}

export const hoursRenderer = (day) => {
  const today = dayjs().startOf('day');
  const todayStr = today.format('YYYY-MM-DD');
  const start = dayjs(`${todayStr}T${day?.startTime}`);
  const end = dayjs(`${todayStr}T${day?.endTime}`);
  if (day?.startTime && day?.endTime) {
    if (end.diff(start, 'h') < 1) {
      return today.diff(start.subtract(1, 'day'), 'h') + end.diff(today, 'h');
    }
    return end.diff(start, 'h');
  } else {
    return 'N/A';
  }
};

export function parseDocuments(documents, key = 'type') {
  return Array.from(documents || []).reduce((prev, curr) => {
    if (curr.subEntityId) {
      const prevValues = prev[`${curr.subEntityType}-${curr.subEntityId}`] || {};
      prev[`${curr.subEntityType}-${curr.subEntityId}`] = {
        ...prevValues,
        [curr[key].title]: [curr, ...(prevValues?.[curr[key].title] || [])],
      };
    } else {
      prev[curr[key].title] = (prev[curr[key].title] || []).concat({
        ...curr,
        type: curr.mimeType,
      });
    }
    return prev;
  }, {});
}

export function extractDocuments(data) {
  return Object.keys(data.documents).reduce((prev, curr) => {
    return prev.concat(data.documents[curr]);
  }, []);
}

function getDocumentsArray(keys, documents) {
  const attachedDocuments = keys
    .reduce((prev, key) => {
      if (Array.isArray(documents[key])) {
        return prev.concat(documents[key]);
      } else {
        const keys = Object.keys(documents[key] || {});
        const nestedDocuments = getDocumentsArray(keys, documents[key]);
        return prev.concat(nestedDocuments);
      }
    }, [])
    .filter((v) => v);
  return attachedDocuments;
}

export async function parseDocumentsForServer(values, { documents, allDocuments }) {
  const keys = Object.keys(values.documents || {});
  const attachedDocuments = getDocumentsArray(keys, documents);
  const newAttachments = getDocumentsArray(keys, values.documents);
  const newDocuments = newAttachments
    .filter((i) => !i.id)
    .map((doc) => {
      if (typeof doc.type === 'string' && doc.documentType) {
        return { ...doc, type: doc.documentType };
      }
      return doc;
    });
  const deletedDocuments = attachedDocuments
    .map((i) => i.id)
    .filter((x) => !newAttachments.map((i) => i.id).includes(x));
  let newSavedDocuments = [];
  if (newDocuments.length > 0) {
    newSavedDocuments = await filesService.saveDocuments(newDocuments);
  }
  if (deletedDocuments.length > 0) await filesService.deleteDocuments(deletedDocuments);
  const newAllDocuments = Array.from(allDocuments || [])
    .filter((v) => v)
    .filter((doc) => !deletedDocuments.includes(doc.id))
    .concat(newSavedDocuments.map((doc) => ({ ...doc, documentType: doc.type })));

  return { newSavedDocuments, deletedDocuments, allDocuments: newAllDocuments };
}

export async function getNewUpdatedDeletedDocuments(documents, previousDocuments = []) {
  const documentsToBeAdded = documents.filter((doc) => doc && !doc.id);
  const documentsToBeUpdated = documents.filter((doc) => doc && doc.id);
  let addedDocuments = [];
  let updatedDocuments = [];
  if (documentsToBeAdded.length > 0) {
    addedDocuments = await filesService.saveDocuments(documentsToBeAdded);
  }
  if (documentsToBeUpdated.length > 0) {
    updatedDocuments = await filesService.updateDocuments(documentsToBeUpdated);
  }
  const documentsToBeDeleted = previousDocuments.filter((doc) => !documents.find((v) => v.id === doc.id));
  if (documentsToBeDeleted.length > 0) {
    await filesService.deleteDocuments(documentsToBeDeleted.map((v) => v.id));
  }

  return { documentsToBeAdded, documentsToBeUpdated, documentsToBeDeleted, addedDocuments, updatedDocuments };
}

export async function getCenterSnapshot(facilityId, dispatch) {
  try {
    let { careFacility: center, timestamp } = await providerService.getCenterSnapshot(facilityId, 11 /* CR_PENDING */);
    let documents = await filesService.getDocumentsSnapshot({
      entityType: 'CareFacility',
      entityId: facilityId,
      timestamp,
    });
    const ageGroups = center.ageGroups || [];
    center.staff = Array.from(center.staff || []).map((item) => ({
      ...item,
      startDate: item?.person?.position?.startDate,
      endDate: item?.person?.position?.endDate,
    }));
    const credentialsData = center.staff.find((staff) => staff.efmDesignate) || {};
    const exp = Array.from(credentialsData.experiences || []);
    const trainings = Array.from(credentialsData.trainings || []);
    const educations = Array.from(credentialsData.educations || []).map((i) => ({
      ...i,
      completed: i.completed || false,
    }));
    const experiences = exp.filter((i) => i.experienceType);
    const otherExperiences = exp
      .filter((i) => !i.experienceType)
      .map((i) => ({
        ...i,
        value: i.experienceTitle,
        label: i.experienceTitle,
      }));
    const inServices = trainings.filter((i) => i.inService);
    const preServices = trainings.filter((i) => !i.inService);

    const hoursOfOperation = center?.hoursOfOperation?.map((item) => {
      const data = { ...item };
      if (item.openingTime) {
        data.openingTime = dayjs(item.openingTime, 'HH:mm');
      }
      if (item.closingTime) {
        data.closingTime = dayjs(item.closingTime, 'HH:mm');
      }
      return data;
    });

    const standardHours = hoursOfOperation?.filter((item) => item.type === 'REGULAR');
    const beforeSchoolCareHours = hoursOfOperation?.filter((item) => item.type === 'BEFORE_SCHOOL');
    const afterSchoolCareHours = hoursOfOperation?.filter((item) => item.type === 'AFTER_SCHOOL');
    const respiteCareHours = hoursOfOperation?.filter((item) => item.type === 'RESPITE');
    const beforeSchoolCare = beforeSchoolCareHours?.some((item) => item.openingTime && item.closingTime);
    const afterSchoolCare = afterSchoolCareHours?.some((item) => item.openingTime && item.closingTime);
    const respiteCare = respiteCareHours?.some((item) => item.openingTime && item.closingTime);
    const weekendCareProvidedstandardHours = standardHours
      ?.slice(5, 7)
      ?.some((item) => item.openingTime && item.closingTime);
    const weekendCareProvidedrespiteCareHours = respiteCareHours
      ?.slice(5, 7)
      ?.some((item) => item.openingTime && item.closingTime);
    const address = center.address
      ? {
          ...center.address,
          outsideUSA: center.address?.country !== 'US',
        }
      : center.address;
    const billingAddress = center.billingAddress
      ? {
          ...center.billingAddress,
          outsideUSA: center.billingAddress?.country !== 'US',
        }
      : center.billingAddress;
    dispatch(
      centerActions.setCenterSnapshot({
        ...center,
        address,
        billingAddress,
        paymentViaPaperCheck: !center.paymentViaDirectDeposit,
        chargeRegistrationFee: Boolean(center.registrationFee),
        ...credentialsData,
        experiences,
        otherExperiences,
        inServices,
        preServices,
        educations,
        otherExperiencesSelection: otherExperiences.length > 0,
        application: {
          ...center.application,
          hasPointOfContact: Boolean(center.application.pointOfContact),
        },
        standardHours,
        beforeSchoolCareHours,
        afterSchoolCareHours,
        respiteCareHours,
        beforeSchoolCare,
        afterSchoolCare,
        respiteCare,
        weekendCareProvidedstandardHours,
        weekendCareProvidedrespiteCareHours,
        ageGroups: ageGroups.map((item) => ({
          ...item,
          partTime: ['ptHourly', 'ptDaily', 'ptWeekly', 'ptMonthly', 'ptYearly'].reduce(
            (prev, curr) => prev || Boolean(item.rates[curr]),
            false,
          ),
        })),
      }),
    );
    dispatch(documentActions.setAllDocuments(documents));
    dispatch(documentActions.setDocuments(parseDocuments(documents, 'type')));
  } catch (error) {
    newrelic.noticeError(error);
    dispatch(centerActions.setCenterSnapshot(null));
  }
}

export function getFullName(person, opt) {
  return [person?.firstName, person?.lastName].filter(Boolean).join(' ') || '';
}

export function parseCareFacilityData(record) {
  const contact = [record.application?.pointOfContact?.firstName, record.application?.pointOfContact?.lastName]
    .filter((v) => v)
    .join(' ');
  return {
    ...record,
    contact,
    highPriority: record.application?.highPriority,
    submittedDate: record.application?.submittedDate ? dayjs(record.application.submittedDate) : undefined,
    familySubmittedDate: record.submittedDate ? dayjs(record.submittedDate) : undefined,
    assignedDate: record.application?.assignedDate ? dayjs(record.application.assignedDate) : undefined,
    familyAssignedDate: record.assignedDate ? dayjs(record.assignedDate) : undefined,
    assignedTo: record.application?.assignedTo,
    expiryDate: record.application?.assignedDate ? dayjs(record.application.assignedDate) : undefined,
  };
}

export function parseFamilyData(record) {
  return {
    ...record,
    submittedDate: record?.submittedDate ? dayjs(record.submittedDate) : undefined,
    assignedDate: record.assignedDate ? dayjs(record.assignedDate) : undefined,
  };
}

export function setDefaultCountry(values, addressKey) {
  return produce(values, (draft) => {
    if (draft[addressKey]) {
      const address = draft[addressKey];
      const country = address.country;
      address.country = country === 'United States' || !country ? 'US' : country;
    }
  });
}

export function communicationMapper(item) {
  const person = item.createdBy;
  const name = person ? getFullName(person) : 'System Generated';
  return {
    ...item,
    name,
  };
}

export const timeStringValidator = (value) => {
  const today = dayjs().startOf('day').format('YYYY-MM-DD');
  const isValid = dayjs(`${today}T${value?.slice(0, 5)}`, 'YYYY-MM-DDTHH:mm', true).isValid();
  return isValid;
};

export function isInViewport(selector) {
  const container = document.querySelector(selector);
  if (container) {
    const rect = container.getBoundingClientRect();
    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
  }
  return false;
}
export const renderAgeRange = ({ minAge, maxAge, showFullDuration = false }) => {
  const convertAge = (age) => {
    let { years = 0, months = 0, weeks = 0 } = age || {};
    if (typeof age === 'number') {
      weeks = age;
    }
    const totalWeeks = years * 52 + months * 4 + weeks;
    if (totalWeeks < 52) {
      return {
        display: `${weeks} week${weeks !== 1 ? 's' : ''}`,
        months: months > 0 ? `${months} month${months !== 1 ? 's' : ''}` : '',
      };
    } else {
      years = Math.floor(totalWeeks / 52);
      months = Math.round((totalWeeks % 52) / 4);
      return {
        display: `${years} year${years !== 1 ? 's' : ''}`,
        months: months > 0 ? `${months} month${months !== 1 ? 's' : ''}` : '',
      };
    }
  };

  const formatAge = (age) => {
    return age.months ? `${age.display} and ${age.months}` : age.display;
  };

  const minConverted = convertAge(minAge);
  const maxConverted = convertAge(maxAge);

  return (
    <span className="!inline-block">
      {formatAge(minConverted)} - {formatAge(maxConverted)}
    </span>
  );
};

export const getAgeInWeeks = (age) => {
  if (!age) return 0;
  const { years = 0, months = 0, weeks = 0 } = age || {};
  return Number(years) * YEAR_TO_WEEKS + Number(months) * MONTH_TO_WEEKS + Number(weeks);
};

export function hasApplicationExpired(record, index) {
  return index === 0;
  // return record.endDate ? dayjs().diff(dayjs(record.endDate), 'day') >= 90 : false;
}

export const dollarFormatterParserProps = {
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
};

export function getProgramsColumn({ key = 'programs', width = 150, title = 'Programs' }) {
  return {
    title: (props) => <ColumnTitle {...props} title={title} dataIndex="programs" colKey="eligibility" />,
    headerText: title,
    dataIndex: 'programs',
    key,
    width,
    render: (programs) => {
      if (!programs || !programs?.length) return 'N/A';
      const types = Array.from(new Set(programs.map((program) => program.type.title))).join(', ');
      const tooltipContent = programs.map((program) => (
        <span key={program.id}>
          {program.type.title} {program.sponsor.title}
        </span>
      ));
      return <Tooltip title={<div className="flex flex-col">{tooltipContent}</div>}>{types}</Tooltip>;
    },
  };
}

export const getAndUpdateDocuments = async (entityType, entityId, actions, dispatch) => {
  try {
    const allDocuments = await filesService.getDocuments({
      entityType,
      entityId,
    });
    dispatch(documentActions.setAllDocuments(allDocuments));
    dispatch(documentActions.setDocuments(parseDocuments(allDocuments)));
  } catch (error) {
    newrelic.noticeError(error);
  }
};

export function isFamilyRole(role) {
  return [
    ROLES.FAMILY,
    ROLES.FAMILY_MANAGER,
    ROLES.FAMILY_COORDINATOR,
    ROLES.FAMILY_QC_MANAGER,
    ROLES.FAMILY_QC_COORDINATOR,
    ROLES.ER_MANAGER,
    ROLES.ER_COORDINATOR,
    ROLES.FAMILY_SUPPORT_MANAGER,
    ROLES.FAMILY_SUPPORT_SPECIALIST,
    ROLES.PARENT_SERVICES_MANAGER,
    ROLES.PARENT_SERVICES_SPECIALIST,
    ROLES.PAYMENT_MANAGER,
    ROLES.PAYMENT_COORDINATOR,
    ROLES.REPORTING_MANAGER,
    ROLES.REPORTING_ANALYST,
    ROLES.FINANCE_MANAGER,
  ].includes(role);
}

export function isFamilyInternalRole(role) {
  return role !== ROLES.FAMILY && isFamilyRole(role);
}

export function removeEmptyObjects(obj) {
  return transform(
    obj,
    (result, value, key) => {
      if (value != null) {
        result[key] = typeof value === 'object' ? removeEmptyObjects(value) : value;
      }
    },
    {},
  );
}

export const getFamilyApplicationReviewNavItems = ({ id, householdId, application, selectedParent, selectedChild }) => {
  let items = [
    {
      label: 'Sponsor',
      key: 'sponsor',
      regex: [/^\/families\/\d+\/applications\/\d+\/review$/, /^\/families\/\d+\/applications\/\d+\/review\/sponsor/],
      to: `/families/${householdId}/applications/${id}/review/sponsor/info`,
    },
  ];

  if (selectedParent) {
    items.push({
      label: (
        <div className="flex justify-between items-center">
          <span className="relative">
            {getFullName(selectedParent)} <span className="absolute -bottom-3 right-0 text-[8px]">Second Parent</span>
          </span>
          <AiFillCaretDown className="ml-4" />
        </div>
      ),
      key: 'secondParent',
      regex: [new RegExp(`\\/families\\/\\d+\\/applications\\/\\d+\\/review\\/secondParent\\/${selectedParent?.id}`)],
      to: `/families/${householdId}/applications/${id}/review/secondParent/${selectedParent?.id}/info`,
      children: application.additionalParents.map((parent) => ({
        label: getFullName(parent),
        key: `secondParent-${parent.id}`,
        regex: [new RegExp(`\\/families\\/\\d+\\/applications\\/\\d+\\/review\\/secondParent\\/${parent.id}`)],
        to: `/families/${householdId}/applications/${id}/review/secondParent/${parent.id}/info`,
      })),
    });
  }
  if (selectedChild) {
    items.push({
      label: (
        <div className="flex justify-between items-center">
          <span className="relative">
            {getFullName(selectedChild)} <span className="absolute -bottom-3 right-0 text-[8px]">Child</span>
          </span>
          <AiFillCaretDown className="ml-4" />
        </div>
      ),
      key: 'children',
      regex: [new RegExp(`\\/families\\/\\d+\\/applications\\/\\d+\\/review\\/children\\/${selectedChild?.id}`)],
      to: `/families/${householdId}/applications/${id}/review/children/${selectedChild?.id}/info`,
      children: (application.children || []).map((child) => ({
        label: getFullName(child),
        key: `children-${child.id}`,
        regex: [new RegExp(`\\/families\\/\\d+\\/applications\\/\\d+\\/review\\/children\\/${child.id}`)],
        to: `/families/${householdId}/applications/${id}/review/children/${child.id}/info`,
      })),
    });
  }

  items = items.concat(
    {
      label: 'Eligibility',
      key: 'eligibility',
      regex: /^\/families\/\d+\/applications\/\d+\/review\/eligibility$/,
      to: `/families/${householdId}/applications/${id}/review/eligibility`,
    },
    {
      label: 'Certificates',
      key: 'certificates',
      regex: [
        /^\/families\/\d+\/applications\/\d+\/review\/certificates/,
        /^\/families\/\d+\/applications\/\d+\/review\/certificates\/add/,
        /^\/families\/\d+\/applications\/\d+\/review\/certificates\/\d+\/update/,
        /^\/families\/\d+\/applications\/\d+\/review\/certificates\/\d+\/summary/,
        /^\/families\/\d+\/applications\/\d+\/review\/certificates\/\d+\/calculate/,
      ],
      to: `/families/${householdId}/applications/${id}/review/certificates`,
    },
    {
      label: 'Finalize Review',
      key: 'finalReview',
      navigationKey: ['finalReview'],
      regex: /^\/families\/\d+\/finalReview$/,
      to: `/families/${householdId}/applications/${id}/review/finalReview`,
    },
    {
      label: 'Messages',
      key: 'messages',
      regex: /^\/families\/\d+\/messages$/,
      to: `/families/${householdId}/messages`,
    },
    {
      label: 'Alerts',
      key: 'alerts',
      regex: /^\/families\/\d+\/alerts$/,
      to: `/families/${householdId}/alerts`,
    },
    {
      label: 'Family Profile',
      key: 'profile',
      regex: /^\/families\/\d+\/profile$/,
      to: `/families/${householdId}/profile`,
    },
  );

  return items;
};

export default {
  downloadFile,
};
