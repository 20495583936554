import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { message } from 'antd';
import { useAsync } from 'react-async-hook';
import Header from 'components/Header';
import commonService from 'services/common.service';

export default function BranchSelection() {
  const { result: branches = [] } = useAsync(async () => {
    try {
      let data = await commonService.getSponsors();
      data = data.filter((branch) => branch.active);
      return data.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);
  return (
    <div className="relative overflow-auto" id="branch-container">
      {/* <div className="h-24 shadow-md bg-white fixed top-0 left-0 right-0 justify-between flex px-8 items-center">
        <Link to="/" className="home-link block h-16" title="Home">
          <img src="/images/ccaoa-logo.png" alt="log" className="h-16" />
        </Link>
        <div>
          <HeaderMenu>
            <MdMenu size={32} className="text-sky-600" />
          </HeaderMenu>
        </div>
      </div> */}
      <Header theme="primary" className="shadow-md bg-white" />
      <div className="flex justify-center flex-col items-center">
        <div className="mt-32">
          <Link to="/" className="home-link block h-16" title="Home">
            <img src="/images/ccaoa-logo.png" alt="log" className="h-28" />
          </Link>
        </div>
        <div className="py-5 my-24">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {' '}
            {branches?.map?.((item) => (
              <BranchItem {...item} key={item.img} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const BranchItem = ({ branchName, iconUrl, alias, description, id: branchId }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const location = pathname.split('/family/')?.[1];
  return (
    <div
      className="h-56 w-56 border justify-center items-center flex-col flex rounded-2xl ring-2 ring-gray-200 hover:ring-primary hover:ring-2 cursor-pointer"
      onClick={() => history.push(`/family/${location}/${branchId}`)}
      data-testid={`/family/${location}/${branchId}`}
    >
      <picture>
        <source srcSet={iconUrl} type="image/webp" />
        <img src={iconUrl} alt="Background" className="object-fill h-24 w-24" />
      </picture>
      <div className="mt-4 text-center ml-8 mr-8">
        <span className="text-primary text-base font-semibold text-center">{branchName}</span>
      </div>
      <p className="line-clamp-2 leading-6 text-center mt-2 text-gray-700 text-ellipsis">{description}</p>
    </div>
  );
};
