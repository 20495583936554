import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import { AnimateSharedLayout } from 'framer-motion';
import { MAP_BRANCH } from 'constants/index';

import styles from './AuthPage.module.less';

export default function AuthPage({ className, children, actions }) {
  const height = use100vh();
  const { pathname } = useLocation();
  const { branchId } = useParams();
  const isFamily = pathname.includes('family');
  let src = isFamily ? `/images/branches/branchBg/BG-Signin-${MAP_BRANCH[branchId]}.webp` : '/images/signin-bg-2.webp';
  let logo = `/images/branches/branchIcon/Icon-Branch-${MAP_BRANCH[branchId]?.toUpperCase()}.webp`;
  if (pathname === '/family/signin') {
    src = '/images/branches/branchBg/BG-Signin-DOD.webp';
    logo = '/images/branches/branchIcon/Icon-Branch-DOD.webp';
  }

  const homeLink = (
    <div className="home-link-container" role="banner">
      <Link to="/" className="home-link" arial-label="Link to home page">
        <img src="/images/ccaoa-logo.png" alt="Logo" />
      </Link>
    </div>
  );

  return (
    <AnimateSharedLayout>
      <div className={classNames(styles.authPage, className, 'layout-page')} style={{ minHeight: height }}>
        <div className={classNames('form-content', className)} role="main">
          <div className="flex-grow"></div>

          <div className="form-area">
            {homeLink}
            {children}
            {typeof actions === 'function' ? actions(pathname) : actions}
          </div>

          <div className="flex-grow"></div>
        </div>

        <div className="background-container" style={{ height }}>
          <picture>
            <source srcSet={src} type="image/webp" />
            <img src={src} alt="Background" className="bg-image object-cover object-center h-screen" />
          </picture>
          {isFamily && (
            <div className="absolute translate-y-full right-0 left-0 ">
              <div className="flex justify-center items-center">
                <picture>
                  <source srcSet={logo} type="image/webp" />
                  <img src={logo} alt="logo" className="object-fill h-96 w-96" />
                </picture>
              </div>
            </div>
          )}
          {/* <div className="bg-info">
            <div className="flex justify-between">
              <h3 className="heading">Child Care Fee Assistance for Child care Providers</h3>
              <picture>
                <source srcSet="/images/circle-outlined.webp" type="image/webp" />
                <source srcSet="/images/circle-outlined.png" type="image/png" />
                <img src="/images/circle-outlined.webp" alt="Play Button" className="w-14 h-14 mr-11 mt-2" />
              </picture>
            </div>

            <div className="text-content">
              This video will help introduce you to the fee assistance programs that Child Care Aware of America
              administers for the Department of Defense. Over the next few minutes we&lsquo;ll provide you with
              information about what these programs offer, who is eligible, and the next steps you&lsquo;ll need to take
              to begin your fee assistance application.
            </div>
          </div> */}
        </div>
      </div>
    </AnimateSharedLayout>
  );
}

AuthPage.propTypes = {
  actions: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
};
AuthPage.defaultProps = {
  className: '',
};
