import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button, message, Spin } from 'antd';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { breakpointsContext, formatDates, getPastDateValidator } from 'utils';
import { Storage } from 'aws-amplify';

import Form from 'components/Form';
import Modal from 'components/Modal';
import { actions, selectUser } from 'features/auth';
import userService from 'services/users.service';
import { NAME_REGEX, SINGLE_REGEX } from 'constants/index';
import DatePicker from 'components/DatePicker';
import UpdatePasswordModal from 'components/Modals/UpdatePasswordModal';
import AddressFields from 'components/AddressFields';
import ViewField from 'components/ViewField';
import useIsMounted from 'hooks/useIsMounted';

Storage.configure({ level: 'private' });

export default function AddProfile() {
  const isMounted = useIsMounted();
  const history = useHistory();
  const [form] = Form.useForm();
  const addressRef = useRef();
  const { lg } = useContext(breakpointsContext);
  const [user, setUser] = useState(null);
  const [applicationAlertVisible, setApplicationAlertVisible] = useState(false);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector(selectUser);
  const datesData = {};
  if (userInfo.birthDate) {
    datesData.birthDate = dayjs(userInfo.birthDate);
  }
  if (userInfo.educationCompletedOn) {
    datesData.educationCompletedOn = dayjs(userInfo.educationCompletedOn);
  }

  const mailingAddressKey = 'address';
  const onPasswordFormClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    if (isMounted.current) {
      getUserInfo();
    }
    async function getUserInfo() {
      try {
        setLoading(true);
        const user = await userService.getCurrentUser();

        const initialValues = { ...(user || {}), ...userInfo };
        if (user?.id) {
          setUser(user);
          if (user.person.address) {
            initialValues.address = user.person.address;
          }
        }
        form.setFieldsValue(initialValues);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Something went wrong. Try again later.');
        history.push('/provider');
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, form, history, isMounted, userInfo]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        values = formatDates(values, [['person', 'dateOfBirth']]);
        const data = {
          ...values,
          userRole: userInfo?.groups?.[0],
          username: userInfo?.username,
          uuid: userInfo?.username,
          email: userInfo?.email,
          enabled: true,
        };
        data.person = { ...(user?.person || {}), ...data.person };
        data.person.address = data.address;
        if (user?.person?.address?.id) {
          data.person.address.id = user?.person?.address?.id;
        }
        delete data.address;
        if (user?.id) {
          data.id = user.id;
        }
        if (user?.person?.id) {
          data.person.id = user.person.id;
        }
        data.person.personType = 'Owner';
        data.person.email = userInfo.email;
        data.person.phone = values.person.phone || userInfo.phone;
        const userResp = await userService.updateUser(data);
        dispatch(actions.updateUser(userResp));
        message.success('Profile updated successfully.', 5);
        history.push('/provider');
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to save user info.');
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history, user?.id, user?.person, userInfo?.email, userInfo?.groups, userInfo.phone, userInfo?.username],
  );
  return (
    <motion.div layoutId="page-step" id="profile-form-container">
      <Spin spinning={loading}>
        <Form
          className="form"
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{ ...userInfo, ...datesData }}
          form={form}
        >
          {(values) => {
            return (
              <>
                <Row className="white-box section">
                  <h3 className="section-title">Applicant Information</h3>
                  <p className="instructions">
                    Field marked with * are mandatory. Please provide the details for the individual completing your
                    application. Details for your child care location will be added later.
                  </p>
                  <Col xs={24}>
                    <Row className="form-cols">
                      <Col xs={24} lg={16} className="col-right">
                        <Row gutter={[20, 0]} className="form-row mt-4">
                          <Col xs={24} className="-mb-3">
                            <ViewField label="User ID" value={userInfo?.username} />
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name={['person', 'firstName']}
                              label="First Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'First Name is required',
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (typeof value === 'string' && value && !value.trim())
                                      return Promise.reject('Invalid First Name');
                                    if (!value || SINGLE_REGEX.test(value) || NAME_REGEX.test(value)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject('Invalid First Name');
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder="First Name" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name={['person', 'lastName']}
                              label="Last Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Last Name is required',
                                },
                                () => ({
                                  validator(rule, value) {
                                    if (typeof value === 'string' && value && !value.trim())
                                      return Promise.reject('Invalid Last Name');
                                    if (!value || SINGLE_REGEX.test(value) || NAME_REGEX.test(value)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject('Invalid Last Name');
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder="Last Name" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name={['person', 'middleName']}
                              label="Middle Name"
                              rules={[
                                () => ({
                                  validator(rule, value) {
                                    if (!value || SINGLE_REGEX.test(value) || NAME_REGEX.test(value)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject('Invalid Middle Name');
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder="Middle Name" />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name={['person', 'dateOfBirth']}
                              label="Date of Birth"
                              rules={[
                                {
                                  required: true,
                                  message: 'Date of Birth is required.',
                                },
                                getPastDateValidator('Date of Birth should be in past.'),
                              ]}
                            >
                              <DatePicker placeholder="Date of Birth" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="white-box section">
                  <h3 className="section-title">Address</h3>
                  <AddressFields form={form} searchRef={addressRef} addressKey={mailingAddressKey} required={{}} />
                </div>
                <Row className="white-box section">
                  <div className="actions w-full">
                    <Button onClick={() => history.push('/provider')} disabled={loading}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        form.resetFields();
                        addressRef.current.clear();
                      }}
                      disabled={loading}
                    >
                      Clear Form
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                      Save
                    </Button>
                  </div>
                </Row>
              </>
            );
          }}
        </Form>
      </Spin>
      <UpdatePasswordModal visible={visible} setVisible={onPasswordFormClose} />
      <Modal
        maskClosable={false}
        width={lg ? 770 : 'auto'}
        visible={applicationAlertVisible}
        setVisible={() => setApplicationAlertVisible(false)}
        getContainer="#profile-form-container"
      >
        <span className="title">Your application process is incomplete</span>
        <h3 className="heading mt-5">Please add a facility or care location and complete your profile.</h3>
        <p className="text w-full md:w-2/3 mx-auto">
          To use the provider portal please add at least one facility and build your profile. Your application will be
          reviewed for eligibility and verification by provider services team.
        </p>
        <div className="flex flex-col-reverse sm:flex-row sm:justify-center space-y-reverse space-y-3 sm:space-y-0 sm:space-x-3 mt-10">
          <Button className="w-full md:w-40" onClick={() => history.push('/provider/application')}>
            Cancel
          </Button>
          <Button type="primary" className="w-full md:w-40">
            Add Care Location
          </Button>
        </div>
      </Modal>
    </motion.div>
  );
}

AddProfile.propTypes = {
  history: PropTypes.object,
};
