import React, { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table from 'components/Table';
import useTablePagination from 'hooks/useTablePagination';
import { formatDateTimeToLocal } from 'utils';
import { actions } from 'features/add-center';
import { selectOrganization } from 'features/auth';
import Card from 'components/Card/Card';
import { Add, Renew } from '@carbon/icons-react';

const statusMap = {
  INCOMPLETE: 'Not Submitted',
  PENDING: 'Submitted',
  SUBMITTED: 'Submitted',
  ASSIGNED: 'Received',
  RECEIVED: 'Received',
  VERIFYING_PROVIDER_ELIGIBILITY: 'Verifying Eligibility',
  QC: 'Verifying',
  MANAGER_REVIEW: 'Verifying',
  UNDER_REVIEW: 'Verifying',
  REVIEW_REQUIRED: 'Verifying',
  VERIFYING: 'Verifying',
  FINAL_REVIEW_COMPLETE: 'Approved',
  COMPLETED: 'Completed',
  QC_REJECTED: 'Verifying',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

const PAGE_SIZE = 10;

export default function CareFacilities() {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);
  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
    }),
    [],
  );

  const mapper = useCallback((record) => {
    return {
      ...record,
      status: statusMap[record.application.status?.status?.title || 'INCOMPLETE'],
      street: record.address?.street,
      location: [record.address?.city, record.address?.state].filter((v) => v).join(', '),
    };
  }, []);
  const applications = useTablePagination({
    url: `/providers/organizations/${organization?.id}/care-facilities`,
    params,
    mapper,
  });
  const reload = useCallback(async () => {
    return await applications.fetchMore({
      current: 1,
      pageSize: PAGE_SIZE,
    });
  }, [applications]);

  const allColumns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'businessLegalName',
        key: 'businessLegalName',
        render: (businessLegalName) => <span>{businessLegalName || 'N/A'}</span>,
      },
      {
        title: 'Facility Type',
        dataIndex: 'careFacilityType',
        key: 'careFacilityType',
        render: (value) => {
          let _text = 'N/A';
          if (value?.title) {
            if (value.title === 'Family Child Care Home') {
              _text = 'Home';
            } else if (value.title === 'Child Care Center') {
              _text = 'Center';
            } else {
              _text = value.title;
            }
          }

          return <span>{_text}</span>;
        },
      },
      {
        title: 'Provider Address',
        dataIndex: 'location',
        key: 'location',
        render: (value, record) => {
          return record.address ? (
            <div className="flex">
              <span>{record.street}</span>
              <span>
                {record.street ? ', ' : ''}
                {value}
              </span>
            </div>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: 'Status',
        dataIndex: ['application', 'status', 'status', 'publicStatus'],
        key: 'publicStatus',
        render: (status) => <span className="font-semibold">{status?.title || 'INCOMPLETE'}</span>,
      },
      {
        title: 'Last Modified',
        dataIndex: ['application', 'status', 'lastModifiedDate'],
        key: 'lastModifiedDate',
        render: (date) => formatDateTimeToLocal(date),
      },
    ],
    [],
  );

  return (
    <div className={classNames('layout-page')}>
      <div id="page-top-placeholder" />

      <div className="p-5">
        <Card
          noBodyPadding
          title="My Locations"
          extra={
            <div className="flex space-x-3">
              {applications.length !== 0 && (
                <Button
                  type="primary"
                  icon={<Add size={20} className="ml-[-8px] mr-[-2px]" />}
                  onClick={() => history.push('/provider/facility')}
                >
                  Add New Location
                </Button>
              )}

              <Button
                icon={<Renew />}
                disabled={applications.loading}
                loading={applications.loading}
                onClick={reload}
                aria-label="Reload"
                title="Reload"
                data-testid="reload-btn"
              />
            </div>
          }
        >
          {applications.loading ? (
            <div className="h-[200px] flex">
              <Spin spinning className="w-full m-auto" />
            </div>
          ) : applications.pagination.total === 0 ? ( //.pagination here works more reliably than using .data, no pages = no applications
            history.push('/provider/facility')
          ) : (
            <Table
              allColumns={allColumns}
              rowKey="id"
              loading={applications.loading}
              data={applications.data}
              pagination={applications.pagination}
              onChange={applications.onChange}
              size="large"
              rowClassName={(record, index) => {
                const _cursorClassName = 'cursor-pointer';
                if (dayjs(record?.expirationDate).diff(dayjs(Date.now()).format('YYYY-MM-DD'), 'day') < 60) {
                  return `[&_td]:!bg-orange-200 ${_cursorClassName}`;
                }

                if (record.status === 'Rejected') return `[&_td]:!bg-red-200 ${_cursorClassName}`;

                return index % 2 === 0
                  ? `[&_td]:!bg-white h-9 ${_cursorClassName}`
                  : `[&_td]:!bg-light-bg h-9 ${_cursorClassName}`;
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    dispatch(actions.setCenterInfo({}));
                    history.push(`/provider/facility/${record.id}`);
                  },
                };
              }}
              scroll={{ x: 1200 }}
            />
          )}
        </Card>
      </div>
    </div>
  );
}
