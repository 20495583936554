import React, { useState, useCallback, useEffect, memo } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';

import Form from 'components/Form';
import Field from 'components/Field';
import useQueryParams from 'hooks/useQueryParams';
import { ageGroupOptions, paramsToQueryString } from 'utils';
import PlaceAutoComplete from 'components/PlaceAutoComplete';
import Modal from 'components/Modal';
import Select from 'components/Select';
import { CHILD_CARE_TYPES } from 'constants/index';
import FilterContainer from 'components/SearchProviders/FilterContainer';

function StepForm({ className, visible, setVisible, onSubmit }) {
  const history = useHistory();
  const { params } = useQueryParams();
  const [step, setStep] = useState(0);
  const [placeLoading, setPlaceLoading] = useState(false);
  const next = useCallback(() => {
    setStep((v) => v + 1);
  }, []);
  const prev = useCallback(() => {
    setStep((v) => v - 1);
  }, []);
  const handleSubmit = useCallback(
    (values) => {
      setVisible(false);
      const queryString = paramsToQueryString(values);
      history.push(`/search?${queryString}`);
    },
    [history, setVisible],
  );

  const steps = [
    {
      id: 'location',
      title: 'Location',
      content: (
        <Field name="location" label="">
          <PlaceAutoComplete placeholder="Start finding someone you trust..." setLoading={setPlaceLoading} />
        </Field>
      ),
    },
    // {
    //   title: 'Add Dates',
    //   content: (
    //     <Field name="dateRange" label="" className="date-range-container">
    //       <RangePicker onApply={next} screen="xs" xs />
    //     </Field>
    //   ),
    // },
    {
      id: 'childCareTypes',
      title: 'Child Care Type',
      content: (
        <Field name="childCareTypes" label="">
          <Select
            ariaLabel="Select Child Care Type"
            placeholder="Add type"
            name="childCareTypes"
            getOptionLabel={(opt) => opt.name}
            isOptionSelected={(opt, [value]) => opt.value === value}
            onChangeFormatter={(v) => v?.value}
            options={CHILD_CARE_TYPES.map((opt) => ({ ...opt, label: opt.name }))}
          />
        </Field>
      ),
    },
    {
      id: 'ageInMonths',
      title: 'Child Age',
      content: (
        <Field name="ageInMonths" label="" className="age-container">
          <Select
            ariaLabel="Select Child Age"
            name="ageInMonths"
            placeholder="Add age"
            options={ageGroupOptions}
            isOptionSelected={(opt, [value]) => opt.id === Number(value)}
            onChangeFormatter={(v) => v?.id}
          />
        </Field>
      ),
    },
  ];

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (step === steps.length - 1) {
          setVisible(false);
        } else {
          next();
        }
      }
    },
    [next, setVisible, step, steps.length],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
  useEffect(() => {
    setStep(0);
  }, [visible]);

  const showClose = step === 0;
  const showPrevious = step > 0;
  const showNext = step < steps.length - 1;
  const showDone = step === steps.length - 1;
  return (
    <Modal destroyOnClose visible={visible} setVisible={setVisible} bodyStyle={{ padding: 20 }} closable>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          ...params,
          location: params.placeId,
          ...(params.age ? { age: Number(params.age) } : {}),
        }}
        className=""
        preserve
        data-testid="search-form"
      >
        {(values) => {
          const nextDisabled = placeLoading || (!values.location && step === 0);
          return (
            <>
              <div className="steps-content">
                {steps.map((item, index) => (
                  <div
                    key={item.title}
                    style={{
                      display: index === step ? 'block' : 'none',
                      width: '100%',
                    }}
                  >
                    <FilterContainer title={item.title} id={item.id} titleClassName="mb-4 font-medium text-gray-600">
                      {item.content}
                    </FilterContainer>
                  </div>
                ))}
              </div>
              <div className="actions mt-20">
                {showClose && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVisible(false);
                    }}
                  >
                    Close
                  </Button>
                )}
                {showPrevious && <Button onClick={prev}>Previous</Button>}
                {showNext && (
                  <Button type="primary" onClick={next} disabled={nextDisabled}>
                    Next
                  </Button>
                )}
                {showDone && (
                  <Button
                    htmlType="submit"
                    type="primary"
                    id="search-submit-btn"
                    data-testid="submit-button"
                    className="submit-btn gradient flex justify-center items-center space-x-4"
                    style={{ outline: 'none' }}
                  >
                    {/* <SearchOutlined className="search-icon" /> */}
                    <MdSearch className="search-icon" />
                    Search
                  </Button>
                )}
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

StepForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
};

export default memo(StepForm);
