import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Layout, Button } from 'antd';
import useBreakpoints from 'hooks/useBreakpoints';
import styles from './Layout.module.less';
import SidebarMenu from 'components/SidebarMenu';
import SwitchRolesMenu from 'components/SwitchRolesMenu';
import { Switcher } from '@carbon/icons-react';

const { Header, Content, Sider } = Layout;

export default function LayoutPage({
  children,
  menuOptions,
  className,
  headerIcons,
  scrollClassName = 'overflow-auto',
}) {
  const breakpoints = useBreakpoints();
  const { xs } = breakpoints;
  const [collapsed, setCollapsed] = useState(false);

  const sidebarAnimationVariants = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 1,
      },
    }),
    closed: {
      clipPath: 'circle(0px at 0px 0px)',
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 40,
      },
    },
  };

  let sidebar = (
    <Sider
      width={xs ? '100%' : 230}
      theme="light"
      trigger={null}
      collapsible={!xs}
      collapsed={collapsed}
      collapsedWidth={70}
      // breakpoint="xxl"
      // onCollapse={(v) => setCollapsed(v)}
      data-testid="sidebar"
    >
      {/* <Menu collapsed={collapsed} breakpoints={breakpoints} menuOptions={menuOptions} /> */}
      <SidebarMenu menuOptions={menuOptions} />
    </Sider>
  );

  if (xs) {
    sidebar = (
      <motion.div
        className="sidebar-container"
        animate={!collapsed ? 'open' : 'closed'}
        variants={sidebarAnimationVariants}
      >
        {sidebar}
      </motion.div>
    );
  }

  useEffect(() => {
    if (localStorage && localStorage.getItem) {
      const collapsedPreference = localStorage.getItem('ccaoa__sidebar-collapsed');
      if (collapsedPreference === 'true') {
        setCollapsed(true);
      }
    }
  }, []);

  return (
    <Layout className={classNames(styles.layout)}>
      <Header theme="light" className="shadow-sm">
        <Button
          type="link"
          icon={<Switcher size={24} />}
          aria-label={collapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
          className="sidebar-trigger !outline-none !ring-0 !shadow-none"
          data-testid="sidemenu-trigger"
          onClick={() => {
            if (localStorage && localStorage.setItem) {
              localStorage.setItem('ccaoa__sidebar-collapsed', collapsed === false ? 'true' : 'false');
            }

            setCollapsed((v) => !v);
          }}
        ></Button>

        <motion.div
          className="trigger-padding"
          // animate={collapsed ? { width: 0 } : { width: xs ? 0 : 150 }}
          transition={{ duration: 0.2 }}
          initial={{ x: xs ? 0 : 150 }}
        ></motion.div>

        <div className="space" />

        <div className="right-menu">
          {headerIcons}
          <SwitchRolesMenu />
        </div>
      </Header>
      <Layout className={classNames('root-ant-layout h-[calc(100vh-50px)] overflow-hidden sticky top-0')}>
        {sidebar}
        <Layout className={scrollClassName}>
          <Content
            className={classNames(
              className,
              'table max-w-[1300px] w-full min-h-[calc(100vh-280px)] mt-[30px] mb-[200px] mx-auto px-4',
            )}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutPage.propTypes = {
  children: PropTypes.any,
  menuOptions: PropTypes.array,
  breadcrumbs: PropTypes.array,
};
