import React, { useEffect } from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import Home from 'containers/Home';
import authRoutes from 'components/Authentication';
import ProtectedRoute from 'components/ProtectedRoute';
import AboutPage from 'containers/AboutPage';
import ProviderSearch from 'containers/ProviderSearch';
import Provider from 'containers/Provider';
import Family from 'containers/Family';
import { breakpointsContext } from 'utils';
import useBreakpoints from 'hooks/useBreakpoints';
import Admin from 'containers/Admin';
import Internal from 'containers/Internal';
import { ROLES } from 'constants/index';
import { selectors } from 'features/auth';
import Loading from 'components/Loading';
import PublicSearch from 'containers/PublicSearch';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isLoaded = useSelector(selectors.selectAuthLoaded);
  const user = useSelector(selectors.selectUser);

  useEffect(() => {
    // Reporting user ID to New Relic
    // @ts-ignore
    if (typeof window !== 'undefined' && window?.newrelic && user?.username) {
      console.log('report', user.username);

      // @ts-ignore
      window?.newrelic?.setUserId?.(user.username);
    }
  }, [user]);

  useEffect(() => {
    message.config({
      top: 100,
      duration: 3,
      rtl: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    newrelic.setCurrentRouteName(location.pathname);
  }, [location.pathname]);

  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  useEffect(() => {
    dispatch({ type: 'APP_LOADED' });
  }, [dispatch]);

  if (!isLoaded) return <Loading />;

  return (
    <breakpointsContext.Provider value={breakpoints}>
      <Switch>
        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />

        <ProtectedRoute exact path="/" message="Navigated to Home Page">
          <Home />
        </ProtectedRoute>

        <ProtectedRoute exact path="/search" message="Navigated to Provider Search Page">
          <PublicSearch />
        </ProtectedRoute>

        <ProtectedRoute
          path={[
            '/search',
            '/alerts',
            '/messages',
            '/documents',
            '/fee-calculator',
            '/families',
            '/providers',
            '/dashboard',
          ]}
          hideFor={[ROLES.FAMILY, ROLES.PROVIDER]}
          message="Navigated to Dashboard"
          authRequired
        >
          <Internal />
        </ProtectedRoute>

        <ProtectedRoute authRequired roleRequired={[ROLES.SYSTEM_ADMIN]} path="/admin" message="">
          <Admin />
        </ProtectedRoute>

        <ProtectedRoute exact path="/provider-search" message="Navigated to Provider Search Page">
          <ProviderSearch />
        </ProtectedRoute>

        <ProtectedRoute exact path="/about" message="Navigated to About Page">
          <AboutPage />
        </ProtectedRoute>

        {authRoutes}

        <ProtectedRoute authRequired path="/provider" roleRequired={[ROLES.PROVIDER]} message="">
          <Provider />
        </ProtectedRoute>

        <ProtectedRoute path="/family" authRequired roleRequired={[ROLES.FAMILY]} message="Family Page">
          <Family />
        </ProtectedRoute>

        <Redirect to="/" />
      </Switch>
    </breakpointsContext.Provider>
  );
}

export default App;
