import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'constants/index';
import { ClearIcon } from 'utils/icons';

export default function DayjsDatePicker({ value, onlyPast, disabledDate, changeOnBlur, ...rest }) {
  const date = value ? (dayjs.isDayjs(value) ? value : dayjs(value)) : value;
  return (
    <div id={`${rest.id}_container`} data-testid={`${rest.id}_container`} className="relative">
      <DatePicker
        value={date}
        {...rest}
        changeOnBlur={changeOnBlur}
        format={['MM/DD/YYYY', ...DATE_FORMAT]}
        getPopupContainer={() => document.getElementById(`${rest.id}_container`)}
        disabledDate={(date) => {
          let disabled = false;
          if (onlyPast) {
            if (date && dayjs(date).isAfter(dayjs().endOf('d'))) {
              disabled = true;
            }
          }
          return disabled || disabledDate(date);
        }}
        data-testid={rest.id}
        clearIcon={
          <ClearIcon
            onClick={() => rest.onChange(null)}
            className={`text-gray-400 w-5 h-5 ml-1 cursor-pointer`}
            id={`${rest.id}-clear`}
          />
        }
        style={{ borderRadius: '2px' }}
      />
    </div>
  );
}

DayjsDatePicker.propTypes = {
  value: PropTypes.any,
  onlyPast: PropTypes.bool,
  disabledDate: PropTypes.func,
  changeOnBlur: PropTypes.bool,
};
DayjsDatePicker.defaultProps = {
  onlyPast: false,
  disabledDate: () => false,
  changeOnBlur: true,
};
