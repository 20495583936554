import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import GoogleMapReact from 'google-map-react';
import uniqBy from 'lodash/uniqBy';

import styles from './ProviderProfile.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors as centerSelectors } from 'features/add-center';
import Loading from 'components/Loading';
import { getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';
import { IoCheckmarkCircleSharp, IoCloseCircle } from 'react-icons/io5';
import AgeGroups from 'components/AgeGroups';
import Form from 'components/Form';
import Table from 'components/Table';
import dayjs from 'dayjs';
import { TIME_FORMAT } from 'constants/index';
import { FaCheck, FaPhoneAlt, FaPlus } from 'react-icons/fa';
import config from 'config';
import Marker from 'components/Marker';
import Card from 'components/Card/Card';

const filter = (item) => (item.openingTime && item.closingTime) || item.overnightCare || item.twentyFourHourCare;
const width = 200;
const dayWeight = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};
const sortByDay = (a, b) => {
  return dayWeight[a.dayOfWeek] - dayWeight[b.dayOfWeek];
};

export default function ProviderProfile({ className, match }) {
  const { id } = match.params;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const center = useSelector(centerSelectors.selectCenterState);
  const centerLoading = useSelector(centerSelectors.selectLoading);
  const programs = Array.from(center?.programs || []).reduce((prev, curr) => {
    if (curr.selected) {
      prev[curr.type.program.title] = Array.from(prev[curr.type.program.title] || []).concat(curr);
    }
    return prev;
  }, {});
  const pointer = useMemo(
    () => ({
      lat: center.address?.location?.lat,
      lng: center.address?.location?.lon,
    }),
    [center.address?.location?.lat, center.address?.location?.lon],
  );
  const isApproved = useMemo(() => center?.application?.status?.status?.title === 'APPROVED', [center]);
  const hoursOfOperation = useMemo(
    () =>
      (center?.hoursOfOperation || []).map((item) => {
        const data = { ...item };
        if (item.openingTime) {
          data.openingTime = dayjs(item.openingTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM);
        }
        if (item.closingTime) {
          data.closingTime = dayjs(item.closingTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM);
        }
        return data;
      }),
    [center?.hoursOfOperation],
  );
  const standardHours = useMemo(
    () => uniqBy(hoursOfOperation?.filter((item) => item.type === 'REGULAR').sort(sortByDay), (item) => item.dayOfWeek),
    [hoursOfOperation],
  );
  const beforeSchoolCareHours = useMemo(
    () =>
      uniqBy(
        hoursOfOperation?.filter((item) => item.type === 'BEFORE_SCHOOL').sort(sortByDay),
        (item) => item.dayOfWeek,
      ),
    [hoursOfOperation],
  );
  const afterSchoolCareHours = useMemo(
    () =>
      uniqBy(
        hoursOfOperation?.filter((item) => item.type === 'AFTER_SCHOOL').sort(sortByDay),
        (item) => item.dayOfWeek,
      ),
    [hoursOfOperation],
  );
  const respiteCareHours = useMemo(
    () => uniqBy(hoursOfOperation?.filter((item) => item.type === 'RESPITE').sort(sortByDay), (item) => item.dayOfWeek),
    [hoursOfOperation],
  );
  const showStandardHours = useMemo(() => standardHours?.some(filter), [standardHours]);
  const showBeforeSchoolCareHours = useMemo(() => beforeSchoolCareHours?.some(filter), [beforeSchoolCareHours]);
  const showAfterSchoolCareHours = useMemo(() => afterSchoolCareHours?.some(filter), [afterSchoolCareHours]);
  const showRespiteCareHours = useMemo(() => respiteCareHours?.some(filter), [respiteCareHours]);

  useEffect(() => {
    dispatch(actions.getCenter(id));
  }, [dispatch, id]);
  if (centerLoading) {
    return <Loading />;
  }
  if (!centerLoading && !center?.businessLegalName) {
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <p className="text-xl font-medium text-gray-400">No provider found!</p>
      </div>
    );
  }
  return (
    <div className={classNames(styles.providerProfile, className)}>
      <Form form={form}>
        <Card title="Provider Profile">
          <div className="flex flex-col md:flex-row md:justify-between gap-4 w-full">
            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-10 w-full lg:w-1/2">
              <div>
                <p className="text-primary uppercase font-semibold">Provider ID#:</p>
                <p className="text-black text-base font-semibold mt-1" data-testid="provider-id">
                  {center.id}
                </p>
              </div>
              <div>
                <p className="text-primary uppercase font-semibold">Business Name:</p>
                <p className="text-black text-base font-semibold mt-1" data-testid="provider-businessLegalName">
                  {center.businessLegalName}
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-10 w-full lg:w-1/2">
              {isApproved && (
                <div>
                  <p className="text-primary uppercase font-semibold">Badges</p>

                  <div className="flex space-x-2">
                    {isApproved && (
                      <div className="badge badge-1 w-8 h-8" data-testid="approved-badge">
                        <img src="/images/badges/approved.png" alt="Approved" title="Approved/Verified" />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div>
                <p className="text-primary uppercase font-semibold">Case Owner</p>
                <p className="text-black text-base font-semibold mt-1">
                  {center.application?.assignedTo ? getFullName(center.application?.assignedTo) : 'Not Assigned'}
                </p>
              </div>
            </div>
          </div>
        </Card>

        <Card collapsible noBodyPadding title="Contact Information" className="mt-8">
          <div className="flex gap-8 p-6">
            <div>
              <h4 className="uppercase text-15 text-primary font-medium">Point of Contact</h4>
              {center.application?.pointOfContact ? (
                <>
                  <p className="text-base font-semibold">{getFullName(center.application?.pointOfContact)}</p>
                  <p className="mb-4 mt-1 text-base">{center.application?.pointOfContact?.position?.name || 'N/A'}</p>
                  <a
                    className="bg-white phone-link h-10 flex items-center justify-center rounded-full text-black font-semibold relative"
                    href={`tel:${center.application?.pointOfContact.phone}`}
                    aria-label={`Phone Number: ${center.application?.pointOfContact.phone}`}
                  >
                    <FaPhoneAlt size={20} className="text-primary absolute left-6" />
                    {getFormattedPhoneNumber(center.application?.pointOfContact.phone)}
                  </a>
                </>
              ) : (
                <p className="text-gray-400 text-15">N/A</p>
              )}
            </div>

            <div>
              <h4 className="uppercase text-15 text-primary font-medium mb-2">Facility Phone Number</h4>
              {center.businessPhone ? (
                <a
                  className="bg-white phone-link h-10 flex items-center justify-center rounded-full text-black font-semibold relative"
                  href={`tel:${center.businessPhone}`}
                  aria-label={`Phone Number: ${center.businessPhone}`}
                >
                  <FaPhoneAlt size={20} className="text-primary absolute left-6" />
                  {getFormattedPhoneNumber(center.businessPhone)}
                </a>
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="px-6">
            <h4 className="uppercase text-15 text-primary font-medium mt-6 mb-1">Address</h4>
            {center.address ? <p className="text-base font-semibold">{getCompletedAddress(center.address)}</p> : 'N/A'}
          </div>

          {center.address?.location?.lat && center.address?.location?.lon && (
            <>
              <div className="px-6">
                <h4 className="uppercase text-15 text-primary font-medium mt-6 mb-1">Map Location</h4>
              </div>

              <div className="h-80 w-full mt-2">
                <GoogleMapReact
                  className="h-80 w-full rounded-b-lg"
                  bootstrapURLKeys={{
                    key: config.REACT_APP_GOOGLE_MAP_API_KEY,
                    libraries: ['geometry', 'drawing', 'places'],
                  }}
                  defaultCenter={pointer}
                  defaultZoom={11}
                >
                  <Marker
                    lat={center.address?.location?.lat}
                    lng={center.address?.location?.lon}
                    id={`marker-${center.id}`}
                    // isActive
                    // focused
                    setProvider={() => {}}
                    handleDirections={() => {}}
                    point={pointer}
                  />
                </GoogleMapReact>
              </div>
            </>
          )}
        </Card>

        <div className="flex flex-col-reverse lg:flex-row gap-5">
          <div className="content flex-1">
            <Card collapsible title="Summary Details" className="my-8">
              <div className="flex flex-col lg:flex-row mb-4">
                <div className="w-full lg:w-1/2">
                  <div className="flex flex-col">
                    <div className="uppercase text-base">Program & Branch Eligibility:</div>
                    <div className="flex flex-wrap pb-1 mt-2">
                      {Object.entries(programs)?.map(([program, sponsors]) => {
                        return (
                          <div className="flex flex-row h-4 mr-3" key={program}>
                            <span className="uppercase font-medium text-sm mr-2">{program}:</span>
                            {sponsors.map((sponsor, index) => (
                              <span className="ml-1" key={sponsor.id}>
                                {sponsor.type.sponsor.title}
                                {index !== sponsors.length - 1 && ','}
                              </span>
                            ))}
                          </div>
                        );
                      })}

                      {center?.programs?.length === 0 && (
                        <span className="text-black text-base">No Program Selected</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <p className="uppercase text-base">Eligibility Status</p>
                  <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2">
                    <StatusCheck title="File Status" checked={isApproved} />
                    <StatusCheck title="Licenses" checked={isApproved} />
                    <StatusCheck title="Accreditation" checked={isApproved} />
                    <StatusCheck title="Compliance" checked />
                  </div>
                </div>
              </div>
            </Card>

            <AgeGroups
              value={center.ageGroups || []}
              onChange={() => {}}
              center={center}
              form={form}
              className="!border-b-0"
              readOnly
            />

            {showStandardHours && (
              <Card collapsible heading="Standard Hours Of Operation" className="mt-8">
                <Schedule data={standardHours} />
              </Card>
            )}

            {showBeforeSchoolCareHours && (
              <Card collapsible heading="Before School Hours Of Operation" className="mt-8">
                <Schedule data={beforeSchoolCareHours} />
              </Card>
            )}

            {showAfterSchoolCareHours && (
              <Card collapsible heading="After School Hours Of Operation" className="mt-8">
                <Schedule data={afterSchoolCareHours} />
              </Card>
            )}

            {showRespiteCareHours && (
              <Card collapsible heading="Respite Hours Of Operation" className="mt-8">
                <Schedule data={respiteCareHours} />
              </Card>
            )}

            {!showStandardHours && !showBeforeSchoolCareHours && !showAfterSchoolCareHours && !showRespiteCareHours && (
              <Card collapsible heading="Standard Hours Of Operation" className="mt-8">
                <Schedule data={[]} />
              </Card>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}

function StatusCheck({ checked, title }) {
  return (
    <div className="flex gap-x-4">
      <div className="w-5 h-5">
        {checked ? (
          <IoCheckmarkCircleSharp size={20} className="text-green-600" />
        ) : (
          <IoCloseCircle size={20} className="text-red-600" />
        )}
      </div>

      <p className="uppercase tracking-wide">{title}</p>
    </div>
  );
}

function Schedule({ data }) {
  const weekendCare = data?.slice(5, 7)?.some(filter);
  const renderBoolean = useCallback(
    (value) =>
      value ? (
        <FaCheck size={20} className="text-green-600 mt-1" />
      ) : (
        <FaPlus size={20} className="text-red-600 transform rotate-45 mt-1" />
      ),
    [],
  );

  const allColumns = [
    {
      title: 'Day',
      dataIndex: 'dayOfWeek',
      key: 'dayOfWeek',
      render: (text) => <span className="">{text}</span>,
      width,
    },
    {
      title: '24 Hour Care',
      dataIndex: 'twentyFourHourCare',
      key: 'twentyFourHourCare',
      render: renderBoolean,
      width,
    },
    {
      title: 'Opening Time',
      dataIndex: 'openingTime',
      key: 'openingTime',
      render: (time) => <span className="uppercase">{time || 'N/A'}</span>,
      width,
    },
    {
      title: 'Closing Time',
      dataIndex: 'closingTime',
      key: 'closingTime',
      render: (time) => <span className="uppercase">{time || 'N/A'}</span>,
      width,
    },
    {
      title: 'Overnight Availability',
      dataIndex: 'overnightCare',
      key: 'overnightCare',
      render: renderBoolean,
      align: 'center',
      width,
    },
  ];

  return (
    <div className="pb-5">
      {data?.length > 0 ? (
        <>
          <Table
            data={data.slice(0, 5)}
            allColumns={allColumns}
            showColSeparator={false}
            showRowSeparator
            pagination={false}
            xsCols={['dayOfWeek', 'openingTime']}
            smCols={['dayOfWeek', 'openingTime']}
            mdCols={['dayOfWeek', 'openingTime', 'closingTime']}
            lgCols={['dayOfWeek', 'twentyFourHourCare', 'openingTime', 'closingTime']}
            xlCols={['dayOfWeek', 'twentyFourHourCare', 'openingTime', 'closingTime', 'overnightCare']}
            rowClassName={(row, index) =>
              `border-b-2 border-b-white h-16 ${index % 2 === 0 ? '[&_td]:!bg-white' : '[&_td]:!bg-light-bg'}`
            }
          />
          <div className="flex gap-x-5 py-5 px-6 items-center">
            <span className="">{renderBoolean(weekendCare)}</span>{' '}
            <span className="text-base">We offer weekend care</span>
          </div>
          <Table
            data={data.slice(5, 7)}
            allColumns={allColumns}
            showHeader={false}
            showColSeparator={false}
            showRowSeparator
            pagination={false}
            xsCols={['dayOfWeek', 'openingTime']}
            smCols={['dayOfWeek', 'openingTime']}
            mdCols={['dayOfWeek', 'openingTime', 'closingTime']}
            lgCols={['dayOfWeek', 'twentyFourHourCare', 'openingTime', 'closingTime']}
            xlCols={['dayOfWeek', 'twentyFourHourCare', 'openingTime', 'closingTime', 'overnightCare']}
            rowClassName={(row, index) =>
              `border-b-2 border-b-white h-16 ${index % 2 === 0 ? '[&_td]:!bg-white' : '[&_td]:!bg-light-bg'}`
            }
          />
        </>
      ) : (
        <div>
          <p className="text-base">No hours of operation provided.</p>
        </div>
      )}
    </div>
  );
}
