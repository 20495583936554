import PropTypes from 'prop-types';
import React from 'react';
// import InputMask from 'react-input-mask';
import MaskedInput from 'react-maskedinput';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER, FORMATTED_PHONE_NUMBER_REGEX } from 'constants/index';

export default function PhoneNumber({ noStyle, value, onChange, placeholder, readOnly, ...rest }) {
  const onValueChange = (e) => {
    let value = e.target.value;
    if (FORMATTED_PHONE_NUMBER_REGEX.test(value)) {
      value = `+1${value.replace(/\(|\)|\s|\-|\_/g, '')}`;
    }
    onChange(value);
  };
  let formattedValue = value;
  if (!value) formattedValue = '';
  if (/^\+1/.test(value)) {
    formattedValue = value.slice(2);
  }

  return (
    <MaskedInput
      {...rest}
      value={formattedValue}
      onChange={onValueChange}
      className="ant-input"
      mask={PHONE_NUMBER_MASK}
      placeholder={placeholder || PHONE_NUMBER_PLACEHOLDER}
      readOnly={readOnly}
      style={{ borderRadius: '0px' }}
      aria-label="phone number"
    />
  );
}

PhoneNumber.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
