import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';

import Form from 'components/Form';
import Switch from 'components/Switch';
import Select, { AntdSelect } from 'components/Select';
import UserOption from 'components/UserOption';
import { selectActiveRole, selectUser } from 'features/auth';
import { FAMILY_STATUSES, PROVIDER_STATUSES, ROLES } from 'constants/index';
import { commonService } from 'services';
import { getFullName } from 'utils';

export default function DashboardFiltersForm({ isFamilyDashbaord, form }) {
  const currentUser = useSelector(selectUser);
  const activeRole = useSelector(selectActiveRole);
  useEffect(() => {
    if (isFamilyDashbaord) {
      // Family specific logic
      let statuses = [];
      let assigned = undefined;
      let assigneeId = undefined;
      if (activeRole === ROLES.FAMILY_COORDINATOR) {
        statuses = [
          FAMILY_STATUSES.INCOMPLETE,
          FAMILY_STATUSES.PENDING,
          FAMILY_STATUSES.VERIFYING,
          FAMILY_STATUSES.QC_EXCEPTION,
        ];
        assigned = true;
        assigneeId = currentUser?.id;
      } else if (activeRole === ROLES.FAMILY_QC_COORDINATOR) {
        statuses = [FAMILY_STATUSES.READY_FOR_QC, FAMILY_STATUSES.QC_REVIEW];
        assigned = true;
        assigneeId = currentUser?.id;
      } else if (activeRole === ROLES.FAMILY_SUPPORT_SPECIALIST) {
        statuses = [
          FAMILY_STATUSES.INCOMPLETE,
          FAMILY_STATUSES.PENDING,
          FAMILY_STATUSES.VERIFYING,
          FAMILY_STATUSES.QC_EXCEPTION,
        ];
        assigned = undefined;
      } else if (activeRole === ROLES.FAMILY_MANAGER) {
        statuses = [FAMILY_STATUSES.PENDING, FAMILY_STATUSES.MANAGER_REVIEW];
        assigned = false;
      } else if (activeRole === ROLES.FAMILY_QC_MANAGER) {
        statuses = [FAMILY_STATUSES.QC_REVIEW, FAMILY_STATUSES.MANAGER_REVIEW];
        assigned = false;
      }
      form.setFieldsValue({ statuses, assigned, assigneeId });
    } else {
      // Provider specific logic
      let statuses = [];
      let assigned = undefined;
      let assigneeId = undefined;
      if (activeRole === ROLES.PROVIDER_COORDINATOR) {
        statuses = [
          PROVIDER_STATUSES.INCOMPLETE,
          PROVIDER_STATUSES.PENDING,
          PROVIDER_STATUSES.VERIFYING,
          PROVIDER_STATUSES.QC_REJECTED,
          // PROVIDER_STATUSES.QC_EXCEPTION,
        ];
        assigned = true;
        assigneeId = currentUser?.id;
      } else if (activeRole === ROLES.PROVIDER_QC_COORDINATOR) {
        statuses = [PROVIDER_STATUSES.QC_REVIEW];
        assigned = true;
        assigneeId = currentUser?.id;
      } else if (activeRole === ROLES.PROVIDER_SUPPORT_SPECIALIST) {
        statuses = [
          PROVIDER_STATUSES.INCOMPLETE,
          PROVIDER_STATUSES.PENDING,
          // PROVIDER_STATUSES.QC_EXCEPTION,
        ];
      } else if (activeRole === ROLES.PROVIDER_MANAGER) {
        statuses = [PROVIDER_STATUSES.PENDING];
        assigned = false;
      } else if (activeRole === ROLES.PROVIDER_QC_MANAGER) {
        statuses = [PROVIDER_STATUSES.READY_FOR_QC];
        assigned = false;
      } else if (activeRole === ROLES.PROVIDER_BGC_COORDINATOR) {
        statuses = [PROVIDER_STATUSES.VERIFYING];
        assigned = true;
      } else if (activeRole === ROLES.PROVIDER_BGC_MANAGER) {
        statuses = [PROVIDER_STATUSES.VERIFYING];
        assigned = true;
      } else if (activeRole === ROLES.PROVIDER_REVIEWER) {
        statuses = [PROVIDER_STATUSES.DOC_REVIEW];
        assigned = true;
        assigneeId = currentUser?.id;
      } else if (activeRole === ROLES.PROVIDER_REVIEWER_MANAGER) {
        statuses = [PROVIDER_STATUSES.MANAGER_DOC_REVIEW, PROVIDER_STATUSES.DOC_REVIEW_REQUIRED];
        assigned = true;
      }
      form.setFieldsValue({ statuses, assigned, assigneeId });
    }
  }, [activeRole, currentUser?.id, form, isFamilyDashbaord]);

  const { loading: statusesLoading, result: statusOptions = [] } = useAsync(
    async (isFamily) => {
      return commonService.get(
        isFamily ? '/families/application-status-types/internal' : '/providers/application-status-types/internal',
        { cache: { interpretHeader: false } },
      );
    },
    [isFamilyDashbaord],
  );
  const { loading: loadingPrograms, result: programOptions = [] } = useAsync(async () => {
    return commonService.get('/common/programs?active=true', { cache: { interpretHeader: false } });
  }, []);
  const statusSelectProps = useMemo(
    () => ({
      mode: 'multiple',
      options: (statusOptions || []).map((opt) => ({
        label: opt.description,
        value: opt.title,
        className: 'py-2.5 hover:bg-primary hover:bg-opacity-10',
      })),
      loading: statusesLoading,
      maxTagCount: 'responsive',
      placeholder: 'Select Statuses',
    }),
    [statusOptions, statusesLoading],
  );
  const programSelectProps = useMemo(
    () => ({
      mode: 'multiple',
      options: (programOptions || []).map((opt) => ({
        label: `${opt.type?.title} ${opt.sponsor?.title}`,
        value: opt.id,
        className: 'py-2.5 hover:bg-primary hover:bg-opacity-10',
      })),
      loading: loadingPrograms,
      maxTagCount: 'responsive',
      placeholder: 'Select Programs',
    }),
    [loadingPrograms, programOptions],
  );

  const { loading: loadingAssignees, result: assignees = [] } = useAsync(async () => {
    return commonService.get(
      `/users/all?role=${isFamilyDashbaord ? ROLES.FAMILY_COORDINATOR : ROLES.PROVIDER_COORDINATOR}`,
    );
  }, []);

  const assignmentOptions = useMemo(() => {
    let options = [{ id: undefined, title: 'All' }];
    if (activeRole !== ROLES.FAMILY_QC_MANAGER) {
      options.push({ id: true, title: 'Assigned Only' });
    }
    options = options.concat({ id: false, title: 'Unassigned Only' });
    return options;
  }, [activeRole]);

  return (
    <Form layout="vertical" form={form} initialValues={{ statuses: [], assigned: undefined }}>
      <div>
        <div className="flex gap-3">
          <Form.Item label="Statuses" name="statuses" className="w-1/2 mb-2">
            <AntdSelect {...statusSelectProps} />
          </Form.Item>

          {isFamilyDashbaord && (
            <Form.Item label="Programs" name="programIds" className="w-1/2 mb-2">
              <AntdSelect {...programSelectProps} />
            </Form.Item>
          )}
        </div>

        <div className="mt-2 mb-2 grid grid-cols-1 md:grid-cols-3  gap-x-3 col-span-7 xxl:col-span-3">
          <Form.Item label="Assignment" name="assigned" className="mb-2">
            <Select
              options={assignmentOptions}
              isOptionSelected={(opt, [value]) => opt.id === value}
              onChangeFormatter={(v) => v?.id}
            />
          </Form.Item>

          <Form.Item label="Assignee" name="assigneeId" className="mb-2">
            <Select
              options={[
                { id: undefined, title: 'Any User', roles: [] },
                { id: currentUser?.id, title: 'Me', roles: [], username: currentUser?.email },
                ...(assignees || []).map((assignee) => ({ ...assignee, title: getFullName(assignee) })),
              ]}
              isOptionSelected={(opt, [value]) => opt.id === value}
              onChangeFormatter={(v) => v?.id}
              isLoading={loadingAssignees}
              components={{ Option: UserOption }}
            />
          </Form.Item>

          <Form.Item label=" " name="highPriorityOnly" className="mb-2" valuePropName="checked">
            <Switch noLabel="" yesLabel="High Priority Only" />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}
