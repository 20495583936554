import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useQueryParams from 'hooks/useQueryParams';
import StepForm from 'components/StepForm';
import styles from './CompactSearch.module.less';
import { dateRangeToString } from 'utils';
import { actions } from 'features/search';

function CompactSearch({ className, location }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { params: values } = useQueryParams();
  const { dateRange } = values;
  const date = dateRangeToString(dateRange);
  useEffect(() => {
    dispatch(actions.resetSearch());
  }, [dispatch]);

  return (
    <div
      id="compact-search-container"
      className={classNames(styles.compactSearch, className)}
      onClick={(e) => {
        if (e.target.id === 'compact-search-container') setVisible(true);
      }}
    >
      <img src="/images/magnify.png" alt="search" className="icon" />
      <>
        <span className="value location" title={location?.formatted_address}>
          {location ? location.formatted_address : 'Add Location'}
        </span>{' '}
        . <span className="value date-range">{date ? date : 'Add Dates'}</span>
      </>
      <StepForm visible={visible} setVisible={setVisible} location={location} />
    </div>
  );
}

CompactSearch.propTypes = {
  className: PropTypes.string,
  location: PropTypes.any,
};

export default memo(CompactSearch);
