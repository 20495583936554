import { useMemo } from 'react';
import dayjs from 'dayjs';
import Form from 'components/Form';
import DatePicker from 'components/DatePicker';
import FormItem from 'components/FormItem';
import { getDisabledDateValidator, isSameDate } from 'utils';

export default function useStartEndDates({
  form,
  startDateFieldProps = {},
  endDateFieldProps = {},
  startDatePickerProps = {},
  endDatePickerProps = {},
  hideChangeRequestHighlights = false,
}) {
  const startDateRule = useMemo(() => [{ required: true, message: 'Start Date is required' }], []);
  const endDateRule = useMemo(() => [{ required: true, message: 'End Date is required' }], []);
  const startDateRules = useMemo(
    () => startDateFieldProps.rules || startDateRule,
    [startDateFieldProps.rules, startDateRule],
  );
  // const startDateFieldName = useMemo(() => endDateFieldProps.name || 'endDate', [endDateFieldProps.name]);
  const endDateRules = useMemo(() => endDateFieldProps.rules || endDateRule, [endDateFieldProps.rules, endDateRule]);
  // const endDateFieldName = useMemo(() => endDateFieldProps.name || 'endDate', [endDateFieldProps.name]);

  const startDate = Form.useWatch(startDateFieldProps.name || 'startDate', form);
  const endDate = Form.useWatch(endDateFieldProps.name || 'endDate', form);
  // useEffect(() => {
  //   if (form && form.isFieldTouched(endDateFieldName)) {
  //     form.validateFields([endDateFieldName]);
  //   }
  // }, [endDateFieldName, form, startDate, startDateFieldProps.name]);
  // useEffect(() => {
  //   if (form && form.isFieldTouched(startDateFieldName)) {
  //     form.validateFields([startDateFieldName]);
  //   }
  // }, [startDateFieldName, endDate, endDateFieldProps.name, form]);
  return [
    <FormItem
      name="startDate"
      label="Start Date"
      isSame={isSameDate}
      {...startDateFieldProps}
      rules={[
        ...startDateRules,
        getDisabledDateValidator((date) => {
          if (!endDate) {
            return false;
          }
          const end = dayjs(endDate);
          return date && dayjs(date).startOf('day')?.isSameOrAfter(end.startOf('day'));
        }, 'Start Date should be before End Date.'),
      ]}
      hideChangeRequestHighlights={hideChangeRequestHighlights}
      dependencies={[endDateFieldProps.name, ...(startDateFieldProps.dependencies || [])]}
    >
      <DatePicker placeholder="Start Date" {...startDatePickerProps} changeOnBlur />
    </FormItem>,
    <FormItem
      name="endDate"
      label="End Date"
      isSame={isSameDate}
      {...endDateFieldProps}
      rules={[
        ...endDateRules,
        getDisabledDateValidator((date) => {
          if (!startDate) {
            return false;
          }
          const start = dayjs(startDate);
          return date && dayjs(date).startOf('day').isSameOrBefore(start.startOf('day'));
        }, 'End Date should be after Start Date.'),
      ]}
      hideChangeRequestHighlights={hideChangeRequestHighlights}
      dependencies={[startDateFieldProps.name, ...(endDateFieldProps.dependencies || [])]}
    >
      <DatePicker placeholder="End Date" {...endDatePickerProps} changeOnBlur />
    </FormItem>,
  ];
}
